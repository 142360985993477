@charset "utf-8";

/* ==============================================
トップページ
============================================== */
#indexTop {
  @include props(pt, 110);

  @include mq(tab) {
    @include props(pt, 140);
  }

  @include mq(md) {
    @include props(pt, 120, 750);
  }

  .keyVisual {
    display: block;
    position: relative;

    &Slider {
      @include props(h, 550);
      max-height: none;
      opacity: 0;
      transition: opacity .3s linear;

      &.slick-initialized {
        opacity: 1;
      }

      @include mq(md) {
        @include props(h, 591, 750);
      }

      .item {
        @include props(h, 550);
        max-height: none;

        @include mq(md) {
          @include props(h, 591, 750);
        }
      }

      .slick-list {
        overflow: hidden;
      }

      .slick-arrow {
        position: absolute;
        width: 23px;
        height: 72px;
        color: rgba($color: #fff, $alpha: 0);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: rgba($color: #fff, $alpha: 0);
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;

        &.slick-prev {
          background-image: url(/images/common/slide_back_arrow.svg);
          @include props(l, 40);
        }

        &.slick-next {
          background-image: url(/images/common/slide_next_arrow.svg);
          @include props(r, 40);
        }

        @include mq(md) {
          opacity: 0;
          visibility: hidden;
        }
      }

      .slideBanner {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        max-width: none;

        @include mq(tab) {
          bottom: 20px;
        }

        @include mq(md) {
          @include props(b, 30, 750);
        }

        li {
          flex: 1;
          @include props(h, 24);
          display: flex;
          @include props(mr, 25);
          @include props(ml, 25);

          button {
            @include props(w, 24);
            color: rgba($color: #fff, $alpha: 0);

            &::before {
              content: "";
              @include props(h, 24);
              border-radius: 100%;
              background-color: #c6c2c2;
              display: block;
            }
          }

          &:not(.slick-active) {
            button {
              &::before {
                @include props(w, 24);
                background-color: #fff;
              }
            }
          }

          @include mq(md) {
            @include props(h, 24, 750);
            @include props(mr, 25, 750);
            @include props(ml, 25, 750);

            button {
              @include props(w, 24, 750);

              &::before {
                @include props(h, 24, 750);
              }
            }

            &:not(.slick-active) {
              button {
                &::before {
                  @include props(w, 24, 750);
                }
              }
            }
          }
        }

        .slick-active {
          button {
            &::before {
              @include props(w, 24);
            }
          }

          @include mq(md) {
            button {
              &::before {
                @include props(w, 24, 750);
              }
            }
          }
        }
      }

      .video {
        position: relative;
        overflow: hidden;
        cursor: pointer;

        video {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: auto;
          transform: translate(-50%, -50%);

          @include mq(md) {
            width: auto;
            height: 100%;
          }

          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1001;
          }
        }

        a {
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          height: 100%;
          z-index: 1002;
        }

        .playAll {
          position: absolute;
          @include props(b, 30);
          @include props(l, 140);
          font-size: 2.1rem;
          font-weight: 600;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: #736461;

          &::before {
            content: "";
            display: inline-block;
            vertical-align: sub;
            width: 27px;
            height: 27px;
            margin: 0 17px 0 0;
            background-image: url(/images/common/icon_play.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }

          @include mq(md) {
            @include props(b, 92, 750);
            @include props(l, 55, 750);
            font-size: 1.8rem;

            &::before {
              @include props(w, 50, 750);
              @include props(h, 50, 750);
              @include props(mr, 19, 750);
            }
          }
        }
      }

      .image {
        position: relative;
        overflow: hidden;

        img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: 100%;
          transform: translate(-50%, -50%);

          @include mq(md) {
            width: auto;
            height: 100%;
          }

          &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1001;
          }
        }
      }
    }

    .scroll {
      position: absolute;
      font-family: $mincho-font;
      font-size: 1.8rem;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #736461;
      writing-mode: vertical-rl;
      @include props(b, -150);
      @include props(r, 90);

      &::after {
        content: "";
        display: block;
        @include props(w, 1);
        @include props(h, 254);
        background-color: #707070;
        margin: 0 10px 0 0;
        animation: pathmove 2.5s ease-in-out infinite;
      }

      @include mq(md) {
        @include fz_vw(31);
        @include props(b, -294, 750);
        @include props(r, 20, 750);

        &::after {
          @include props(h, 447, 750);
        }
      }

      @keyframes pathmove {
        0% {
          transform: scale(1, 0);
          transform-origin: 0 0;
        }

        50% {
          transform: scale(1, 1);
          transform-origin: 0 0;
        }

        50.1% {
          transform: scale(1, 1);
          transform-origin: 0 100%;
        }

        100% {
          transform: scale(1, 0);
          transform-origin: 0 100%;
        }
      }
    }
  }

  .message {
    background-image: url(/images/message/message_back.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    @include props(pt, 53);
    @include props(pb, 138);

    @include mq(md) {
      @include props(pt, 96, 750);
      @include props(pb, 148, 750);
    }

    .container {
      &:nth-of-type(2) {
        display: none;
      }
    }

    .messageTit {
      font-family: $mincho-font;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.8;
      letter-spacing: 0.7px;
      color: #736461;
      @include props(mb, 44);

      span{
        font-size: 1.8rem;
        vertical-align: top;
        margin-left: -12px;
      }

      @include mq(md) {
        @include fz_vw(36);
        letter-spacing: 0.9px;
        @include props(mb, 68, 750);

        span{
          @include fz_vw(26);
          @include props(ml, -20, 750);
        }
      }
    }

    .messageTxt {
      font-family: $mincho-font;
      font-size: 2.4rem;
      line-height: 2;
      letter-spacing: 0.6px;
      color: #736461;
      height: 288px;

      .annotation{
        display:  block;
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.79;
        letter-spacing: normal;
        text-align: right;
        color: #736461;
        max-width: 584px;
        margin: 20px auto 0;
      }

      @include mq(massage) {
        height: 336px;
      }

      @include mq(tab) {
        height: 432px;
      }

      @include mq(md) {
        @include fz_vw(30);
        @include props(h, 531, 750);
        letter-spacing: 0.8px;

        .annotation{
          @include fz_vw(25);
          @include props(mt, 40, 750);
        }
      }
    }
  }

  .concept {
    background: radial-gradient(at left top, rgba(247, 247, 247, 1) 0%,
        rgba(225, 228, 228, 1) 51%,
        rgba(216, 216, 216, 1) 71%,
        rgba(210, 213, 217, 1) 78%,
        rgba(221, 221, 221, 1) 100%);
    background-size: 100%;
    @include props(pt, 94);
    @include props(pb, 130);

    @include mq(md) {
      @include props(pt, 68, 750);
      @include props(pb, 130, 750);
    }

    .conceptTitBlock {
      text-align: center;

      .conceptLead {
        max-width: 720px;
        margin: 0 auto;
        font-family: $mincho-font;
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 1.71;
        letter-spacing: 1.96px;
        color: #736461;
        border-bottom: solid 1px #736461;
        @include props(pb, 10);
        @include props(mb, 20);

        @include mq(md) {
          @include props(w, 670, 750);
          @include fz_vw(36);
          letter-spacing: 2.52px;
          @include props(pb, 10, 750);
          @include props(mb, 40, 750);
        }
      }

      .conceptTit {
        font-family: $mincho-font;
        font-size: 3.6rem;
        font-weight: 600;
        line-height: 1.69;
        letter-spacing: 2.52px;
        color: #736461;

        @include mq(tab) {
          font-size: 3.4rem;
        }

        @include mq(md) {
          @include fz_vw(42);
          letter-spacing: 2.94px;
          @include props(mb, 30, 750);
        }
      }
    }

    .conceptMainImg {
      max-width: 853px;
      width: 100%;
      margin: 0 auto;
      @include props(mb, 58);
      position: relative; 
      overflow-x: hidden;

      .conceptMainImgPart01{
        position: absolute;
        max-width: 228px;
        top: 124px;
        right: calc(50% - 322px);
        opacity: 0;
        transform: translate3d(20px , 0 , 0);
        transition: all 0.4s ease-out;

        &.isShow{
          opacity: 1;
          transform: translate3d(0 , 0 , 0);
        }

        @include mq(tab) {
          @include props(w, 201, 750);
          @include props(r, 100, 750);
        }

        @include mq(md) {
          @include props(w, 251, 750);
          @include props(t, 107, 750);
          @include props(r, 30, 750);
        }
      }
      .conceptMainImgPart02{
        position: absolute;
        max-width: 256px;
        bottom: 156px;
        left: 14px;
        opacity: 0;
        transform: translate3d(-20px , 0 , 0);
        transition: all 0.4s ease-out;

        &.isShow{
          opacity: 1;
          transform: translate3d(0 , 0 , 0);
        }

        @include mq(tab) {
          @include props(w, 225, 750);
          bottom: 137px;
          @include props(l, 25, 750);
        }

        @include mq(md) {
          @include props(w, 225, 750);
          @include props(b, 137, 750);
          @include props(l, 10, 750);
        }
      }
      .conceptMainImgPart03{
        position: absolute;
        max-width: 159px;
        bottom: 36px;
        right: calc(50% - 146px);
        opacity: 0;
        transform: translate3d(20px , 0 , 0);
        transition: all 0.4s ease-out;

        &.isShow{
          opacity: 1;
          transform: translate3d(0 , 0 , 0);
        }

        @include mq(tab) {
          @include props(w, 140, 750);
          @include props(b, 26, 750);
        }

        @include mq(md) {
          @include props(w, 140, 750);
          @include props(b, 22, 750);
          @include props(r, 230, 750);
        }
      }

      @include mq(md) {
        @include props(mb, 60, 750);
      }
    }

    .conceptLinks {
      display: flex;
      justify-content: space-between;
      @include props(mb, 86);

      @include mq(md) {
        flex-wrap: wrap;
        @include props(mb, 73, 750);

        li {
          &:not(:last-of-type) {
            @include props(mb, 30, 750);
          }
        }
      }
    }

    video {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    .conceptConstruction {
      overflow: hidden;
      @include props(mb, 67);

      @include mq(md) {
        @include props(mb, 106, 750);
      }

      .container {
        position: relative;
        z-index: 1;
        @include props(pb, 134);

        @include mq(md) {
          @include props(pb, 100, 750);
        }

        &::after {
          content: "";
          display: block;
          background-color: #fff;
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 60px;
          left: 20px;
          z-index: -1;

          @include mq(md) {
            @include props(t, 165, 750);
          }
        }
      }

      .conceptConstructionTit {
        @include props(w, 645);
        @include props(mb, 64);
        @include props(pl, 92);
        @include props(pb, 9);
        border-bottom: solid 1px #707070;

        @include mq(md) {
          @include props(w, 375, 750);
          @include props(pl, 54, 750);
          @include props(pr, 57, 750);
          @include props(pb, 10, 750);
          @include props(mb, 55, 750);
          margin-left: -20px;
        }
      }

      .flexColumn {
        display: flex;
        justify-content: space-between;
        @include props(pl, 82);

        @include mq(md) {
          flex-wrap: wrap;
          @include props(pl, 0);
        }

        .movieColumn {
          position: relative;
          max-width: 464px;
          width: 100%;
          padding: 0 10px;

          @include mq(md) {
            max-width: 100%;
            padding: 0;
          }
        }

        .txtColumn {
          position: relative;
          max-width: 464px;
          width: 100%;
          padding: 0 10px;

          @include mq(md) {
            max-width: 100%;
            padding: 0;
          }
        }

        .txt {
          font-size: 1.8rem;
          font-weight: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: left;
          color: #736461;
          @include props(mb, 10);

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
            @include props(mb, 20, 750);
            padding-left: 20px;
          }
        }

        .txtLead {
          display: flex;
          align-items: center;
          @include props(mb, 20);

          span {
            &:nth-of-type(1) {
              font-family: $mincho-font;
              font-size: 3.6rem;
              font-weight: normal;
              line-height: 1.61;
              letter-spacing: normal;
              color: #736461;
            }

            &:nth-of-type(2) {
              font-family: $mincho-font;
              font-size: 1.8rem;
              font-weight: normal;
              line-height: 1.17;
              letter-spacing: 1.26px;
              color: #736461;
            }
          }

          @include mq(tab) {
            span {
              &:nth-of-type(1) {
                font-size: 3.4rem;
              }

              &:nth-of-type(2) {
                font-size: 1.6rem;
              }
            }
          }

          @include mq(md) {
            @include props(mb, 35, 750);
            padding-left: 20px;

            span {
              &:nth-of-type(1) {
                @include fz_vw(69);
                line-height: 0.811;
              }

              &:nth-of-type(2) {
                @include fz_vw(25);
                line-height: 1.18;
                letter-spacing: 1.96px;
              }
            }
          }
        }
      }

      .conceptConstructionCon01 {
        @include props(mb, 68);

        @include mq(md) {
          @include props(mb, 30, 750);
        }

        .movieColumn {
          order: 2;

          video {
            position: absolute;
            top: -100px;
            right: -40px;
          }

          @include mq(tab) {
            video {
              @include props(t, -60, 750);
              right: auto;
            }
          }

          @include mq(md) {
            order: 1;
            margin-left: -20px;
            @include props(mb, 110, 750);

            video {
              width: 100%;
              position: static;
            }
          }
        }

        .txtColumn {
          order: 1;

          @include mq(md) {
            order: 2;
          }
        }
      }

      .conceptConstructionCon02 {
        .movieColumn {
          @include mq(md) {
            img {
              width: 100%;
            }
          }
        }

        .txt {
          @include props(mb, 40);

          @include mq(md) {
            @include props(mt, 35, 750);
            @include props(mb, 35, 750);
          }
        }
      }
    }

    .conceptMaterial {
      overflow: hidden;

      .container {
        position: relative;
        z-index: 1;
        @include props(pb, 45);

        @include mq(md) {
          @include props(pb, 35, 750);
        }

        &::after {
          content: "";
          display: block;
          background-color: #fff;
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 67px;
          right: 20px;
          z-index: -1;
        }
      }

      .conceptMaterialTit {
        @include props(w, 710);
        margin: 0 0 0 auto;
        @include props(mb, 58);
        @include props(pr, 50);
        @include props(pb, 15);
        border-bottom: solid 1px #707070;

        img {
          margin: 0 0 0 auto;
        }

        @include mq(md) {
          margin: 0;
          margin-left: -20px;
          @include props(w, 374, 750);
          @include props(pl, 73, 750);
          @include props(pr, 83, 750);
          @include props(pb, 10, 750);
          @include props(mb, 20, 750);
        }
      }

      .flexColumn {
        display: flex;
        justify-content: space-between;
        @include props(pr, 72);
        @include props(mb, 46);

        @include mq(md) {
          flex-wrap: wrap;
          @include props(pr, 0);
          @include props(mb, 30, 750);
        }

        .movieColumn {
          position: relative;
          max-width: 464px;
          width: 100%;
          padding: 0 10px;

          video {
            position: absolute;
            top: -100px;
            left: 0;
          }

          @include mq(tab) {
            video {
              @include props(t, -60, 750);
            }
          }

          @include mq(md) {
            max-width: 100%;
            padding: 0;
            margin-right: -20px;
            margin-left: 20px;
            @include props(mb, 73, 750);

            video {
              width: 100%;
              position: static;
            }
          }
        }

        .txtColumn {
          position: relative;
          max-width: 464px;
          width: 100%;
          padding: 0 10px 0 20px;

          @include mq(md) {
            max-width: 100%;
            padding: 0 10px;
          }
        }

        .txt {
          font-size: 1.8rem;
          font-weight: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: left;
          color: #736461;
          @include props(mb, 10);

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
            @include props(mb, 20, 750);
            padding-right: 20px;
          }
        }
      }

      .btn02 {
        margin: 0 auto;
      }

    }

    .conceptSoft {
      overflow: hidden;

      .max-container {
        position: relative;
        z-index: 1;
        @include props(pt, 54);
        @include props(pb, 18);

        @include mq(concept) {
          max-width: 1128px;
        }

        @include mq(md) {
          @include props(pt, 55, 750);
          @include props(pb, 93, 750);
        }

        &::after {
          content: "";
          display: block;
          background-image: url(/images/concept/soft_back.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 0;
          right: 20px;
          z-index: -1;

          @include mq(fixed) {
            @include props(r, 118);
          }
        }
      }

      .conceptSoftTit {
        max-width: 609px;
        width: 100%;
        @include props(pr, 155);
        margin: 0 0 0 auto;

        span {
          display: block;
          font-size: 2.8rem;
          font-weight: normal;
          line-height: 1.71;
          letter-spacing: -2.24px;
          text-align: left;
          color: #ffffff;
        }

        @include mq(tab) {
          @include props(mb, 37, 750);
        }

        @include mq(md) {
          @include props(w, 508, 750);
          @include props(pr, 0);
          margin: 0;
          @include props(mb, 57, 750);

          span {
            @include fz_vw(31);
            letter-spacing: -2.48px;
            @include props(mb, 10, 750);
          }
        }
      }

      .conceptSoftCon {
        @include props(pr, 247);
        @include clearfix;

        @include mq(tab) {
          @include props(pr, 60, 750);
        }

        @include mq(md) {
          @include props(pr, 40, 750);
        }

        .left {
          float: left;
          width: 100%;
        }

        .right {
          float: right;
        }
      }

      .conceptSoftConTit {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.8;
        letter-spacing: normal;
        color: #736461;

        img {
          display: inline;
          vertical-align: sub;
          margin-left: 15px;
        }

        @include mq(md) {
          @include fz_vw(36);
          line-height: 1.64;
          letter-spacing: -1.28px;

          img {
            @include props(w, 50, 750);
            @include props(h, 50, 750);
            @include props(ml, 10, 750);
          }
        }
      }

      .conceptSoftConTxt {
        font-size: 1.8rem;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #736461;
        @include props(mb, 10);

        @include mq(md) {
          font-size: 1.2rem;
          line-height: 2;
          @include props(mb, 10, 750);
        }
      }

      .conceptSoftConLinks {
        a {
          font-size: 1.8rem;
          line-height: 1.72;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          text-decoration: underline;

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
          }
        }
      }

      .flexColumn {
        display: flex;
        @include props(mb, 100);

        .imgColumn {
          position: relative;
          max-width: 160px;
          width: 100%;
          @include props(pr, 10);

          img{
            box-shadow: 0 10px 10px 0 rgba(57, 55, 55, 0.34);
            border-radius: 100%;
          }

          @include mq(md) {
            @include props(w, 150, 750);
            @include props(pr, 0);
          }
        }

        .txtColumn {
          position: relative;
          max-width: 353px;
          width: 100%;
          @include props(pl, 10);

          @include mq(md) {
            @include props(pl, 20, 750);
          }
        }
      }
    }

    .conceptMoist {
      overflow: hidden;

      .max-container {
        position: relative;
        z-index: 1;
        @include props(pt, 54);
        @include props(pb, 53);

        @include mq(concept) {
          max-width: 1128px;
        }

        @include mq(md) {
          @include props(pt, 58, 750);
          @include props(pb, 108, 750);
        }

        &::after {
          content: "";
          display: block;
          background-image: url(/images/concept/moist_back.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 0;
          right: 20px;
          z-index: -1;

          @include mq(fixed) {
            @include props(r, 118);
          }
        }
      }

      .conceptMoistTit {
        max-width: 609px;
        width: 100%;
        @include props(pr, 155);
        @include props(mb, 52);

        span {
          display: block;
          font-size: 2.8rem;
          font-weight: normal;
          line-height: 1.71;
          letter-spacing: -2.24px;
          text-align: left;
          color: #736461;
        }

        @include mq(tab) {
          @include props(mb, 37, 750);
        }

        @include mq(md) {
          @include props(w, 508, 750);
          @include props(pr, 0);
          margin: 0;
          @include props(mb, 57, 750);

          span {
            @include fz_vw(31);
            letter-spacing: -2.48px;
            @include props(mb, 10, 750);
          }
        }
      }

      .conceptMoistCon {
        @include props(pr, 143);
        @include clearfix;

        @include mq(md) {
          @include props(pr, 40, 750);
        }

        .left {
          float: left;
        }

        .right {
          float: right;
          @include props(mt, 90);
          @include props(mb, 90);

          .txtColumn {
            max-width: 363px;
          }

          @include mq(md) {
            @include props(mt, 82, 750);
            @include props(mb, 82, 750);
          }
        }

        .center {
          clear: both;
          justify-content: center;
          margin: 0 auto;

          .txtColumn {
            max-width: 477px;
          }
        }
      }

      .conceptMoistConTit {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.8;
        letter-spacing: normal;
        color: #736461;

        img {
          display: inline;
          vertical-align: sub;
          margin-left: 15px;
        }

        @include mq(md) {
          @include fz_vw(36);
          line-height: 1.64;
          letter-spacing: -1.28px;

          img {
            @include props(w, 50, 750);
            @include props(h, 50, 750);
            @include props(ml, 10, 750);
          }
        }
      }

      .conceptMoistConTxt {
        font-size: 1.8rem;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #736461;
        @include props(mb, 10);

        @include mq(md) {
          font-size: 1.2rem;
          line-height: 2;
          @include props(mb, 10, 750);
        }
      }

      .conceptMoistConLinks {
        a {
          font-size: 1.8rem;
          line-height: 1.72;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          text-decoration: underline;

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
          }
        }
      }

      .flexColumn {
        display: flex;

        .imgColumn {
          position: relative;
          max-width: 160px;
          width: 100%;
          @include props(pr, 10);

          img{
            box-shadow: 0 10px 10px 0 rgba(57, 55, 55, 0.34);
            border-radius: 100%;
          }

          @include mq(md) {
            @include props(w, 150, 750);
            @include props(pr, 0);
          }
        }

        .txtColumn {
          position: relative;
          max-width: 353px;
          width: 100%;
          @include props(pl, 10);

          @include mq(md) {
            @include props(pl, 20, 750);
          }
        }
      }
    }

    .conceptSolid {
      overflow: hidden;

      .max-container {
        position: relative;
        z-index: 1;
        @include props(pt, 22);
        @include props(pb, 22);

        @include mq(concept) {
          max-width: 1128px;
        }

        @include mq(md) {
          @include props(pt, 57, 750);
          @include props(pb, 63, 750);
        }

        &::after {
          content: "";
          display: block;
          background-image: url(/images/concept/solid_back.jpg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 0;
          right: 20px;
          z-index: -1;

          @include mq(fixed) {
            @include props(r, 118);
          }
        }
      }

      .conceptSolidTit {
        max-width: 609px;
        width: 100%;
        margin: 0 0 0 auto;
        @include props(pr, 155);
        @include props(mb, 32);

        span {
          display: block;
          font-size: 2.8rem;
          font-weight: normal;
          line-height: 1.71;
          letter-spacing: -2.24px;
          text-align: left;
          color: #736461;
        }

        @include mq(tab) {
          @include props(mb, 37, 750);
        }

        @include mq(md) {
          @include props(w, 508, 750);
          @include props(pr, 0);
          margin: 0;
          @include props(mb, 57, 750);

          span {
            @include fz_vw(31);
            letter-spacing: -2.48px;
            @include props(mb, 10, 750);
          }
        }
      }

      .conceptSolidCon {
        @include props(pr, 143);
        @include clearfix;

        @include mq(md) {
          @include props(pr, 40, 750);
        }

        .left {
          float: left;

          .txtColumn {
            max-width: 417px;
          }
        }

        .right {
          float: right;
          @include props(mt, 135);
          @include props(mb, 68);

          .txtColumn {
            max-width: 363px;
          }

          @include mq(md) {
            @include props(mt, 84, 750);
            @include props(mb, 115, 750);
          }
        }

        .center {
          clear: both;
          justify-content: center;
          margin: 0 auto;

          .txtColumn {
            max-width: 629px;
          }
        }
      }

      .conceptSolidConTit {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1.8;
        letter-spacing: normal;
        color: #736461;

        img {
          display: inline;
          vertical-align: sub;
          margin-left: 15px;
        }

        @include mq(md) {
          @include fz_vw(36);
          line-height: 1.64;
          letter-spacing: -1.28px;

          img {
            @include props(w, 50, 750);
            @include props(h, 50, 750);
            @include props(ml, 10, 750);
          }
        }
      }

      .conceptSolidConTxt {
        font-size: 1.8rem;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #736461;
        @include props(mb, 10);

        @include mq(md) {
          font-size: 1.2rem;
          line-height: 2;
          @include props(mb, 10, 750);
        }
      }

      .conceptSolidConLinks {
        a {
          font-size: 1.8rem;
          line-height: 1.72;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          text-decoration: underline;

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
          }
        }
      }

      .flexColumn {
        display: flex;

        .imgColumn {
          position: relative;
          max-width: 160px;
          width: 100%;
          @include props(pr, 10);

          img{
            box-shadow: 0 10px 10px 0 rgba(57, 55, 55, 0.34);
            border-radius: 100%;
          }

          @include mq(md) {
            @include props(w, 150, 750);
            @include props(pr, 0);
          }
        }

        .txtColumn {
          position: relative;
          max-width: 353px;
          width: 100%;
          @include props(pl, 10);

          @include mq(md) {
            @include props(pl, 20, 750);
          }
        }
      }
    }

    .conceptFabric {
      overflow: hidden;
      @include props(mt, 84);

      @include mq(md) {
        @include props(mt, 70, 750);
      }

      .container {
        position: relative;
        z-index: 1;
        @include props(pb, 10);

        @include mq(md) {
          @include props(pb, 72, 750);
        }

        &::after {
          content: "";
          display: block;
          background-color: #fff;
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 65px;
          left: 20px;
          z-index: -1;
        }
      }

      .conceptFabriclTit {
        @include props(w, 645);
        @include props(mb, 77);
        @include props(pl, 32);
        @include props(pb, 10);
        border-bottom: solid 1px #707070;

        @include mq(md) {
          @include props(w, 374, 750);
          @include props(pl, 76, 750);
          @include props(pr, 108, 750);
          @include props(pb, 10, 750);
          @include props(mb, 44, 750);
          margin-left: -20px;
        }
      }

      .flexColumn {
        display: flex;
        justify-content: space-between;
        @include props(pl, 32);

        @include mq(md) {
          flex-wrap: wrap;
          @include props(pl, 0);
        }

        .movieColumn {
          position: relative;
          max-width: 464px;
          width: 100%;
          padding: 0 10px;
          order: 2;

          .movie01 {
            position: absolute;
            @include props(t, -110);
          }

          .movie02 {
            max-width: 168px;
            @include props(mt, 170);

            .txt {
              font-size: 1.8rem;
              font-weight: 600;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: left;
              color: #736461;
            }
          }

          @include mq(tab) {
            .movie02 {
              @include props(mt, 140, 750);
            }
          }

          @include mq(md) {
            max-width: 100%;
            padding: 0;
            order: 1;

            .movie01 {
              position: static;
              margin-left: -20px;

              img {
                width: 100%;
              }
            }

            .movie02 {
              @include props(w, 288, 750);
              @include props(mt, 20, 750);
              margin-left: 20px;

              .txt {
                font-size: 1.2rem;
              }
            }
          }
        }

        .txtColumn {
          position: relative;
          max-width: 448px;
          width: 100%;
          padding: 0 10px;
          order: 1;

          @include mq(md) {
            max-width: 100%;
            order: 2;
            padding: 0 0 0 20px;
            @include props(mt, 32, 750);
          }
        }

        .txt {
          font-size: 1.8rem;
          font-weight: normal;
          line-height: 1.78;
          letter-spacing: normal;
          text-align: left;
          color: #736461;
          @include props(mb, 15);

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
            @include props(mb, 20, 750);
            padding-right: 20px;
          }
        }
      }

      .conceptFabricConLinks {
        a {
          font-size: 1.8rem;
          line-height: 1.72;
          letter-spacing: normal;
          text-align: left;
          color: #707070;
          text-decoration: underline;

          @include mq(md) {
            font-size: 1.2rem;
            line-height: 2;
          }
        }
      }
    }
  }

  .features {
    @include props(pt, 113);
    @include props(pb, 10);
    background-color: #f8f8f8;

    @include mq(md) {
      @include props(pt, 53, 750);
      @include props(pb, 10, 750);
    }

    .featuresTit {
      font-family: $mincho-font;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1.71;
      letter-spacing: 1.96px;
      text-align: center;
      color: #736461;
      border-bottom: solid 1px #736461;
      max-width: 720px;
      width: 100%;
      padding: 0 10px;
      margin: 0 auto;
      @include props(mb, 80);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.56;
        letter-spacing: 2.52px;
        @include props(w, 670, 750);
        @include props(pb, 14, 750);
        @include props(mb, 68, 750);
      }
    }

    .featuresCon {
      @include props(mb, 54);

      @include mq(md) {
        @include props(mb, 0);

        &:not(:last-of-type) {
          @include props(mb, 70, 750);
        }
      }

      .featuresConTit {
        font-size: 2.1rem;
        font-weight: 600;
        line-height: 1.8;
        letter-spacing: 0.53px;
        text-align: center;
        color: #736461;
        @include props(mb, 10);

        @include mq(md) {
          @include fz_vw(32);
          letter-spacing: 0.8px;
          @include props(mb, 36, 750);
        }
      }

      .flexColumn {
        display: flex;
        justify-content: center;
        @include props(mb, 10);

        .img {
          max-width: 444px;
          width: 100%;

          .featuresConTxt {
            padding: 0 10px;
          }
        }

        .type {
          max-width: 444px;
          width: 100%;
        }

        @include mq(md) {
          flex-wrap: wrap;

          .img {
            max-width: 100%;

            .featuresConTxt {
              padding: 0;
            }

            img {
              width: 100%;
            }
          }

          .type {
            max-width: 100%;

            &:not(:last-of-type) {
              @include props(mb, 20, 750);
            }

            img {
              width: 100%;
            }
          }
        }
      }

      .featuresConTxt {
        max-width: 730px;
        width: 100%;
        font-size: 1.8rem;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #736461;
        margin: 0 auto;

        &.center {
          text-align: center;
        }

        @include mq(md) {
          font-size: 1.2rem;
          line-height: 2;
          @include props(mt, 30, 750);
          @include props(mb, 30, 750);

          &.center {
            text-align: left;
          }
        }
      }

      .featuresConSubTit {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: #736461;
        @include props(mb, 18);

        @include mq(md) {
          @include fz_vw(32);
          @include props(mb, 57, 750);
        }
      }

      .typeTit {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: center;
        color: #736461;
        @include props(mb, 6);

        @include mq(md) {
          @include fz_vw(26);
          @include props(mb, 10, 750);
        }
      }

      .typeCon {
        .txt {
          font-size: 1.4rem;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;
          color: #736461;
          @include props(mb, 14);

          @include mq(md) {
            @include fz_vw(14);
            @include props(mb, 22, 750);
          }
        }
      }

      .washingLineup {
        max-width: 730px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto 80px;

        @include mq(md) {
          max-width: 100%;
          @include props(mb, 20, 750);
          @include props(mb, 133, 750);
        }

        .washingLineupTit {
          max-width: 56px;
          width: 100%;
          height: 56px;
          @include props(mr, 36);

          img {
            width: 100%;
          }

          @include mq(md) {
            @include props(w, 112, 750);
            height: auto;
            @include props(mr, 34, 750);
          }
        }

        .washingLineupCon {
          max-width: 625px;
          width: 100%;

          @include mq(md) {
            max-width: 100%;
          }

          li {
            display: inline-block;
            @include props(mr, 30);
            @include props(mb, 5);

            a {
              font-size: 1.8rem;
              font-weight: normal;
              letter-spacing: normal;
              color: #736461;
              text-decoration: underline;
            }

            @include mq(md) {
              @include props(mr, 20, 750);
              @include props(mb, 30, 750);

              a {
                font-size: 1.2rem;
                letter-spacing: -.56px;
              }
            }
          }
        }
      }

      .btn01 {
        max-width: 100%;
        @include props(mt, 42);

        @include mq(tab) {
          max-width: 100%;
          @include props(mt, 42, 750);
        }

        @include mq(md) {
          @include props(mt, 42, 750);
        }
      }
    }

    .moreContent {
      max-width: 944px;
      @include props(mt, 278);

      @include mq(md) {
        @include props(mt, 82, 750);
      }

      .toggleCon {
        display: none;
      }

      .featuresMoreConTit {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.6px;
        text-align: center;
        color: #736461;
        border: solid 1px #707070;
        padding: 11px;
        @include props(mb, 31);

        @include mq(md) {
          @include fz_vw(32);
          line-height: 1.88;
          letter-spacing: 0.8px;
          @include props(pt, 24, 750);
          @include props(pb, 11, 750);
          @include props(mb, 64, 750);
        }
      }

      h4 {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.45px;
        text-align: left;
        color: #736461;
        @include props(mb, 20);

        @include mq(md) {
          font-size: 1.2rem;
          letter-spacing: 0.6px;
          @include props(mb, 35, 750);
        }
      }

      p {
        font-size: 1.8rem;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        color: #736461;
        @include props(mt, 30);

        @include mq(md) {
          font-size: 1.2rem;
          line-height: 2;
          @include props(mt, 35, 750);
          @include props(mb, 60, 750);
        }
      }

      .flexColumn {
        display: flex;
        justify-content: space-between;
        @include props(mb, 40);

        @include mq(md) {
          flex-wrap: wrap;
          @include props(mb, 0);

          img {
            width: 100%;
          }
        }

        .flexColumnCon {
          max-width: 49.2%;
          width: 100%;

          @include mq(md) {
            max-width: 100%;
          }
        }

        .imgColumn {
          max-width: 49.2%;
          width: 100%;

          @include mq(md) {
            max-width: 100%;
          }
        }

        .txtColumn {
          max-width: 49.2%;
          width: 100%;

          @include mq(md) {
            max-width: 100%;
          }
        }
      }

      .featuresMoreCon02 {
        p {
          @include props(mb, 30);

          @include mq(md) {
            @include props(mt, 35, 750);
            @include props(mb, 70, 750);
          }
        }

        .flexColumn {
          @include props(mt, 40);
          @include props(mb, 110);

          @include mq(md) {
            flex-wrap: wrap;
            @include props(mt, 0);
            @include props(mb, 0);
          }
        }

        .txtColumn {
          h4 {
            @include props(mb, 10);

            @include mq(md) {
              @include props(mt, 64, 750);
              @include props(mb, 0);
            }
          }

          p {
            @include props(mt, 20);

            @include mq(md) {
              @include props(mt, 39, 750);
            }
          }
        }
      }
    }
  }

  .lineup {
    @include props(pt, 54);
    @include props(pb, 22);
    background-color: #fff;

    @include mq(md) {
      @include props(pt, 82, 750);
      @include props(pb, 85, 750);
    }

    .lineupTit {
      max-width: 720px;
      width: 100%;
      margin: 0 auto;
      font-family: $mincho-font;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #736461;
      border-bottom: solid 2px #736461;
      @include props(pb, 7);
      @include props(mb, 48);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.56;
        letter-spacing: 2.52px;
        border-bottom: solid 1px #736461;
        @include props(w, 670, 750);
        @include props(pb, 14, 750);
        @include props(mb, 91, 750);
      }
    }

    .lineupCon {
      max-width: 944px;

      &:last-of-type {
        .img {
          @include props(mb, 0);
        }
      }
    }

    .lineupConTit {
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 1.48;
      letter-spacing: normal;
      text-align: center;
      color: #736461;
      background-color: #f8f8f8;
      border-top: solid 2px #736461;
      border-bottom: solid 2px #736461;
      @include props(pt, 14);
      @include props(pb, 10);
      @include props(mb, 20);

      span {
        display: block;
        font-size: 1.8rem;
      }

      @include mq(md) {
        @include fz_vw(36);
        @include props(pt, 23, 750);
        @include props(pb, 19, 750);
        @include props(mb, 40, 750);

        span {
          @include fz_vw(28);
        }
      }
    }

    .lineupConTxt {
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: center;
      color: #343434;

      @include mq(md) {
        font-size: 1.2rem;
        line-height: 2;
        text-align: left;

        &:not(:last-of-type) {
          @include props(mb, 20, 750);
        }
      }
    }

    .linupTable {
      table-layout: auto;
      table-layout: fixed;
      @include props(mt, 68);
      @include props(mb, 43);

      @include mq(md) {
        @include props(mt, 54, 750);
        @include props(mb, 105, 750);
      }

      thead {
        font-size: 1.4rem;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #343434;

        th {
          font-weight: normal;
        }

        @include mq(md) {
          display: none;
        }
      }

      tbody {
        font-size: 1.4rem;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #343434;
        border-bottom: solid 2px #736461;

        @include mq(md) {
          line-height: 1.56;
        }
      }

      tr {
        @include mq(md) {
          border-top: solid 1px #736461;
          display: grid;
          grid-template-columns: 1.5fr 1fr;
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          @include props(pt, 10, 750);
          @include props(pb, 10, 750);
        }
      }

      td {
        border-top: solid 2px #736461;

        @include mq(md) {
          border-top: none;
          display: block;
        }
      }

      .productImg {
        max-width: 23%;
        width: 100%;
        padding: 5px 4%;

        @include mq(md) {
          grid-area: 2 / 1 / 4 / 2;
          max-width: 100%;
          width: 100%;
          padding: 0;
          @include props(w, 270, 750);
        }
      }

      .productName {
        max-width: 27%;
        width: 100%;
        padding: 5px 2%;

        .flexColumn {
          display: flex;
          align-items: center;
        }

        .img {
          max-width: 31px;
          width: 100%;
          margin: 0;
          @include props(mr, 10);
        }

        @include mq(md) {
          grid-area: 1 / 1 / 2 / 2;
          max-width: 100%;
          width: 100%;
          align-self: center;
          padding: 0;
          @include props(pr, 20, 750);

          .img {
            display: none;
          }
        }
      }

      .productHard {
        max-width: 20%;
        width: 100%;
        padding: 5px 2%;

        @include mq(md) {
          grid-area: 1 / 2 / 3 / 3;
          max-width: 100%;
          width: 100%;
          align-self: center;
          padding: 0;

          &::before {
            content: "";
            display: block;
            @include props(w, 51, 750);
            @include props(h, 51, 750);
            background-image: url(/images/common/icon_washing.svg);
            background-size: contain;
            background-repeat: no-repeat;
            @include props(mb, 40, 750);
          }
        }
      }

      .productNum {
        max-width: 20%;
        width: 100%;
        padding: 5px 2%;

        @include mq(md) {
          grid-area: 3 / 2 / 4 / 3;
          max-width: 100%;
          width: 100%;
          align-self: start;
          padding: 0;
        }
      }
    }

    .img {
      max-width: 721px;
      margin: 0 auto;
      @include props(mb, 95);

      @include mq(md) {
        max-width: 100%;
        @include props(mb, 182, 750);
      }
    }
  }

  .shop {
    background-image: url(/images/shop/purchase_back.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include props(pt, 67);
    @include props(pb, 71);
    position: relative;

    @include mq(md) {
      @include props(pt, 82, 750);
      @include props(pb, 124, 750);
    }

    .shopTit {
      max-width: 720px;
      width: 100%;
      margin: 0 auto;
      font-family: $mincho-font;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #736461;
      border-bottom: solid 2px #736461;
      @include props(pb, 7);
      @include props(mb, 87);

      @include mq(md) {
        @include fz_vw(36);
        line-height: 1.56;
        letter-spacing: 2.52px;
        border-bottom: solid 1px #736461;
        @include props(w, 670, 750);
        @include props(pb, 14, 750);
        @include props(mb, 61, 750);
      }
    }

    .shopLinks {
      max-width: 720px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;

      li {
        @include props(pl, 50);
        @include props(pr, 50);
      }

      a {
        font-size: 2.1rem;
        line-height: 1.76;
        letter-spacing: normal;
        text-align: left;
        color: #58595b;
        text-decoration: underline;
      }

      @include mq(md) {
        max-width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;

        li {
          @include props(pl, 0);
          @include props(pr, 0);

          &:not(:last-of-type) {
            @include props(mb, 30, 750);
          }
        }

        a {
          font-size: 1.6rem;
        }
      }
    }

    .pageTopFixed {
      position: absolute;
      @include props(w, 100);
      @include props(r, 130);
      @include props(t, -20);
      cursor: pointer;
      z-index: 10000;

      @include mq(tab) {
        @include props(r, 60);
      }

      @include mq(md) {
        position: fixed;
        @include props(w, 100, 750);
        @include props(h, 100, 750);
        @include props(r, 55, 750);
        top: auto;
        bottom: 20px;
        transition: opacity .8s .8s;

        &.hidden {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
@charset "utf-8";

/* =====================================================
フッター
===================================================== */
#footer{
  background-color: #e2e2e2;
  .footerCon{
    @include props(pt, 22);
    @include props(pb, 23);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(md) {
      @include props(pt, 63, 750);
      @include props(pb, 42, 750);
    }
  }
  .snsLinks{
    display: flex;
    align-items: center;

    .snsLinksTit{
      font-family: $base-noto-font;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #231815;
    }

    .snsLinksCon{
      max-width: 25px;
      @include props(ml, 26);

      @include mq(md) {
        @include props(w, 65, 750);
        margin: 0;
        @include props(mr, 60, 750);
      }
    }
  }
  .footerLangueage{
    display: flex;
    align-items: center;

    @include mq(md) {
      justify-content: center;
      @include props(mb, 60, 750);
    }

    .footerLangueageTit{
      font-family: $base-noto-font;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: #231815;

      @include mq(md) {
        font-size: 1.4rem;
        letter-spacing: 1.68px;
        color: #888888;
      }
    }

    .footerLangueageCon{
      font-family: $base-noto-font;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #231815;
      @include props(ml, 46);
      position: relative;

      span{
        display: flex;
        align-items: center;
        color: #231815;
        border-left: solid 1px #231815;
        border-right: solid 1px #231815;
        @include props(pl, 46);
        @include props(pr, 22);
        cursor: pointer;

        &.open{
          &::after{
            transform: rotate(180deg);
            transform-origin: center;
          }
        }

        &::after{
          content: "";
          display: block;
          @include props(w, 16);
          @include props(h, 8);
          background-image: url(/images/common/icon_arrow_lang.svg);
          background-repeat: no-repeat;
          background-size: contain;
          @include props(ml, 34);
          transition: transform .6s;
        }
      }

      .langChangeCon{
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        @include props(b, 22);
        background-color: #e2e2e2;

        li{
           width: 100%;
        }

        a{
          display: block;
          text-align: center;
          @include props(pt, 5);
          @include props(pb, 5);
        }
      }

      @include mq(tab) {
        .langChangeCon{
          @include props(b, 22, 750);
        }
      }

      @include mq(md) {
        font-size: 1.4rem;
        letter-spacing: 1.68px;
        @include props(ml, 45, 750);

        .langChangeCon{
          @include props(b, 44, 750);
          background-color: #fff;
        }

        span{
          border-left: solid 1px #c9c9c9;
          border-right: none;
          @include props(pl, 45, 750);
          @include props(pr, 45, 750);

          &::after{
            @include props(w, 35, 750);
            @include props(h, 14, 750);
            @include props(ml, 45, 750);
          }
        }
      }
    }
  }
  .pageTop{
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 1.44px;
    text-align: left;
    color: #333;
    cursor: pointer;

    &::before{
      content: "";
      display: block;
      @include props(w, 20, 750);
      @include props(h, 20, 750);
      border-top: solid 2px #333;
      border-left: solid 2px #333;
      transform: rotate(45deg);
      margin: 0 auto 2px;
    }
  }

  .footerCopyright{
    background-color: #fff;
    @include props(pt, 37);
    @include props(pb, 45);
    @include props(pl, 125);
    @include props(pr, 125);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq(tab) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include mq(md) {
      display: block;
      @include props(pt, 60, 750);
      @include props(pb, 137, 750);
    }

    .footerNishikawaLogo{
      @include mq(md) {
        @include props(w, 291, 750);
        margin: 0 auto 9px;
        @include props(mt, 70, 750);

        img{
          width: 100%;
        }
      }
    }

    .copyrightBox{
      display: flex;
      align-items: center;

      @include mq(md) {
        display: block;
      }
    }

    .copyright{
      font-family: $base-noto-font;
      font-size: 1rem;
      line-height: 1;
      letter-spacing: 1px;
      text-align: center;
      color: #707070;
      padding: 0 0 0 42px;

      @include mq(md) {
        display: block;
        padding: 0;
      }
    }

    .ContactBox{
      a{
        font-size: 1.4rem;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        text-decoration: underline;
        color: #58595b;
      }

      @include mq(md) {
        text-align: center;
        @include props(mt, 56, 750);
      }
    }
  }
}
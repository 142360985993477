@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;

// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;

//基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC&family=Noto+Sans+TC&display=swap');
$base-noto-font:"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

$base-font: "游ゴシック", 
YuGothic, 
'Yu Gothic', 
'Hiragino Sans', 
'ヒラギノ角ゴシック', 
'Hiragino Kaku Gothic ProN', 
'ヒラギノ角ゴ Pro W3', 
'Hiragino Kaku Gothic Pro', 
'メイリオ', 
Meiryo, 
Verdana, 
'Noto Sans SC', 
'Noto Sans TC', 
sans-serif;

$mincho-font: 'YuMincho', 
'游明朝', 
"ヒラギノ明朝 Pro W3", 
"Hiragino Mincho Pro", 
"HiraMinProN-W3", 
"HGS明朝E", 
Verdana, 
serif;

@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400&family=Sawarabi+Mincho&display=swap');
$Sawarabi :'Sawarabi Mincho',
sans-serif;

$Kiwi :'Kiwi Maru',
serif;

// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 767.9px)", //ie対策の小数点
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1366px)",
  "concept": "screen and (min-width : 1146px) and (max-width : 1365px)",
  "massage": "screen and (min-width : 812px) and (max-width : 940px)",
) !default;

$properties: ('p': 'padding',
'pt': 'padding-top',
'pl': 'padding-left',
'pb': 'padding-bottom',
'pr': 'padding-right',
'm': 'margin',
'mt': 'margin-top',
'ml': 'margin-left',
'mb': 'margin-bottom',
'mr': 'margin-right',
'w': 'width',
'h': 'height',
't': 'top',
'l': 'left',
'b': 'bottom',
'r': 'right',
) !default;

/* ================================================================================
importファイル
================================================================================ */

// @mixin定義
@import "foundation/mixin";

// @extend定義
@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";

/************************************
トップページ
***********************************/

// トップページ
@import "page/index";
@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC&family=Noto+Sans+TC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400&family=Sawarabi+Mincho&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*タブレット設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, "Noto Sans SC", "Noto Sans TC", sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767.9px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767.9px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767.9px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767.9px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, "Noto Sans SC", "Noto Sans TC", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  background-color: transparent;
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.container {
  max-width: 1128px;
  padding: 0 20px;
  margin: auto;
}

.max-container {
  max-width: 1326px;
  padding: 0 20px;
  margin: auto;
}

a {
  color: #555;
}

.btn01 {
  max-width: 500px;
  display: block;
  width: 100%;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, "Noto Sans SC", "Noto Sans TC", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-radius: 20px;
  background-color: #ffffff;
  padding-top: 2.19619vw;
  padding-bottom: 2.19619vw;
  padding-left: 1.0981vw;
  padding-right: 1.0981vw;
  cursor: pointer;
}

@media screen and (min-width: 1366px) {
  .btn01 {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .btn01 {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .btn01 {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .btn01 {
    padding-right: 15px;
  }
}

.btn01::after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/icon_win.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 26px;
  margin-left: 1.46413vw;
  vertical-align: sub;
}

@media screen and (min-width: 1366px) {
  .btn01::after {
    margin-left: 20px;
  }
}

.btn01:hover {
  opacity: 0.7;
  transition: opacity .3s;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 {
    max-width: 49%;
    font-size: 1.6rem;
  }
  .btn01::after {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 767.9px) {
  .btn01 {
    max-width: 100%;
    font-size: 2.4rem;
    font-size: 1.75695vw;
    border-radius: 15px;
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn01 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .btn01 {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn01 {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn01 {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767.9px) {
  .btn01::after {
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
    margin-left: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn01::after {
    margin-left: 35px;
  }
}

.btn02 {
  max-width: 498px;
  display: block;
  width: 100%;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, "Noto Sans SC", "Noto Sans TC", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-radius: 96px;
  background-color: #f5f5f5;
  padding-top: 2.19619vw;
  padding-bottom: 2.19619vw;
  padding-left: 1.0981vw;
  padding-right: 1.0981vw;
  cursor: pointer;
}

@media screen and (min-width: 1366px) {
  .btn02 {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .btn02 {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .btn02 {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .btn02 {
    padding-right: 15px;
  }
}

.btn02::after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/icon_win.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 26px;
  margin-left: 1.46413vw;
  vertical-align: sub;
}

@media screen and (min-width: 1366px) {
  .btn02::after {
    margin-left: 20px;
  }
}

.btn02:hover {
  opacity: 0.7;
  transition: opacity .3s;
}

@media screen and (max-width: 767.9px) {
  .btn02 {
    max-width: 100%;
    font-size: 2.4rem;
    font-size: 1.75695vw;
    border-radius: 15px;
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn02 {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .btn02 {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn02 {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn02 {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767.9px) {
  .btn02::after {
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
    margin-left: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn02::after {
    margin-left: 35px;
  }
}

.btn03 a {
  max-width: 500px;
  display: block;
  width: 100%;
  font-family: "游ゴシック", YuGothic, "Yu Gothic", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, "Noto Sans SC", "Noto Sans TC", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-radius: 20px;
  background-color: #ffffff;
  padding-top: 2.19619vw;
  padding-bottom: 2.19619vw;
  padding-left: 1.0981vw;
  padding-right: 1.0981vw;
  cursor: pointer;
}

@media screen and (min-width: 1366px) {
  .btn03 a {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .btn03 a {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .btn03 a {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .btn03 a {
    padding-right: 15px;
  }
}

.btn03 a::after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/icon_win.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 26px;
  margin-left: 1.46413vw;
  vertical-align: sub;
}

@media screen and (min-width: 1366px) {
  .btn03 a::after {
    margin-left: 20px;
  }
}

.btn03 a:hover {
  opacity: 0.7;
  transition: opacity .3s;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn03 a {
    max-width: 49%;
    font-size: 1.6rem;
  }
  .btn03 a::after {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 767.9px) {
  .btn03 a {
    max-width: 100%;
    font-size: 2.4rem;
    font-size: 1.75695vw;
    border-radius: 15px;
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn03 a {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .btn03 a {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn03 a {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn03 a {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767.9px) {
  .btn03 a::after {
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
    margin-left: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .btn03 a::after {
    margin-left: 35px;
  }
}

@media screen and (max-width: 767.9px) {
  .btn03 {
    max-width: 100%;
    width: 100%;
  }
}

.toggleBtn {
  max-width: 498px;
  width: 100%;
  padding-top: 2.19619vw;
  padding-bottom: 2.19619vw;
  padding-left: 1.0981vw;
  padding-right: 1.0981vw;
  margin: 0 auto;
  margin-bottom: 2.56223vw;
  border-radius: 96px;
  background-color: #ffffff;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  cursor: pointer;
}

@media screen and (min-width: 1366px) {
  .toggleBtn {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .toggleBtn {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .toggleBtn {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .toggleBtn {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1366px) {
  .toggleBtn {
    margin-bottom: 35px;
  }
}

.toggleBtn::after {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  width: 15px;
  height: 15px;
  border-right: solid 3px #736461;
  border-bottom: solid 3px #736461;
  transform: rotate(45deg);
  margin-left: 1.31772vw;
  transition: transform .6s;
}

@media screen and (min-width: 1366px) {
  .toggleBtn::after {
    margin-left: 18px;
  }
}

.toggleBtn.open::after {
  transform: rotate(225deg);
  transform-origin: 50% 60%;
}

@media screen and (max-width: 767.9px) {
  .toggleBtn {
    font-size: 2.4rem;
    font-size: 1.75695vw;
    border-radius: 15px;
    padding-top: 5.06667vw;
    padding-bottom: 5.06667vw;
    margin-bottom: 14.4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .toggleBtn {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .toggleBtn {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .toggleBtn {
    padding-top: 38px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .toggleBtn {
    padding-bottom: 38px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .toggleBtn {
    margin-bottom: 108px;
  }
}

@media screen and (max-width: 767.9px) {
  .toggleBtn::after {
    vertical-align: top;
    width: 1.86667vw;
    max-width: 14px;
    height: 1.86667vw;
    max-height: 14px;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .toggleBtn::after {
    margin-left: 30px;
  }
}

/* IE11とEdge表示用のCSS　*/
@media all and (-ms-high-contrast: none) {
  #indexTop .features .moreContent .featuresMoreConTit {
    padding: 15px 11px 5px;
  }
}

.modalChart {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 901px;
  width: 100%;
  z-index: 99999;
  transition: opacity .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modalChart {
    max-width: 95%;
  }
}

@media screen and (max-width: 767.9px) {
  .modalChart {
    max-width: 100%;
  }
}

.modalChart.isShow {
  opacity: 1;
  visibility: visible;
}

.modalChart .modalChartCon {
  position: relative;
  background-color: #fff;
  border: solid 1px #707070;
  padding-top: 7.32064vw;
  padding-right: 2.92826vw;
  padding-left: 2.92826vw;
  padding-bottom: 6.14934vw;
  max-height: 95vh;
  overflow-y: scroll;
}

@media screen and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-bottom: 84px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modalChart .modalChartCon {
    padding-top: 13.33333vw;
    padding-bottom: 11.2vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-top: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-bottom: 84px;
  }
}

@media screen and (max-width: 767.9px) {
  .modalChart .modalChartCon {
    max-height: 100vh;
    padding-top: 13.33333vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
    padding-bottom: 9.46667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartCon {
    padding-bottom: 71px;
  }
}

.modalChart button {
  position: absolute;
  top: 25px;
  right: 20px;
  width: 37px;
  height: 37px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.modalChart button:hover {
  transform: rotate(180deg);
  transform-origin: center;
}

.modalChart button span {
  display: block;
  width: 50px;
  height: 3px;
  background-color: #434343;
  position: absolute;
  top: 45%;
  left: -20%;
}

.modalChart button span:nth-of-type(1) {
  transform: rotate(45deg);
}

.modalChart button span:nth-of-type(2) {
  transform: rotate(-45deg);
}

@media screen and (max-width: 767.9px) {
  .modalChart button {
    top: 3.33333vw;
    right: 3.2vw;
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart button {
    top: 25px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart button {
    right: 24px;
  }
}

@media screen and (max-width: 767.9px) {
  .modalChart button span {
    width: 5.73333vw;
    max-width: 43px;
    height: 1px;
  }
}

.modalChart .modalChartStart {
  border-radius: 15px;
  background-color: #fffef2;
  padding-top: 2.19619vw;
  padding-bottom: 0.73206vw;
  box-shadow: 3px 2px 17px -3px #888;
}

@media screen and (min-width: 1366px) {
  .modalChart .modalChartStart {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1366px) {
  .modalChart .modalChartStart {
    padding-bottom: 10px;
  }
}

.modalChart .modalChartStart .txt {
  max-width: 32%;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  .modalChart .modalChartStart {
    border-radius: 7px;
    padding-top: 4vw;
    padding-bottom: 1.86667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartStart {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartStart {
    padding-bottom: 14px;
  }
}

.modalChart .modalChartStartTit {
  font-family: "Sawarabi Mincho", sans-serif;
  font-size: 3.9rem;
  font-weight: 500;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #c7c8ca;
  margin: 0 0 15px;
}

@media screen and (max-width: 767.9px) {
  .modalChart .modalChartStartTit {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartStartTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .modalChart .modalChartStartTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartStartTit {
    margin-bottom: 15px;
  }
}

.modalChart .flexColumn {
  display: flex;
  justify-content: space-between;
}

.modalChart .modalChartStartLead {
  font-family: "Kiwi Maru", serif;
  font-size: 2.8rem;
  font-weight: 400;
  line-height: 0.93;
  letter-spacing: -1.12px;
  text-align: center;
  color: #4d4d4f;
  margin: 0 0 15px;
}

@media screen and (max-width: 767.9px) {
  .modalChart .modalChartStartLead {
    font-size: 2.5rem;
    font-size: 1.83016vw;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartStartLead {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .modalChart .modalChartStartLead {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .modalChartStartLead {
    margin-bottom: 15px;
  }
}

.modalChart .txt p {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.72;
  letter-spacing: -1.44px;
  text-align: center;
  color: #4d4d4f;
}

@media screen and (max-width: 767.9px) {
  .modalChart .txt p {
    font-size: 1.6rem;
    font-size: 1.1713vw;
    letter-spacing: -1.12px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .txt p {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .modalChart .txt p {
    font-size: 2.13333vw;
  }
}

.modalChart .softAnswer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: #fbe5d6;
  margin: 5px auto 8px;
}

.modalChart .softAnswer a {
  display: block;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: -1.28px;
  text-align: center;
  color: #000000;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  .modalChart .softAnswer {
    width: 14vw;
    max-width: 105px;
    height: 14vw;
    max-height: 105px;
  }
  .modalChart .softAnswer a {
    font-size: 1.4rem;
    font-size: 1.02489vw;
    font-feature-settings: "palt" 1;
    letter-spacing: -1.56px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .softAnswer a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .modalChart .softAnswer a {
    font-size: 1.86667vw;
  }
}

.modalChart .moistAnswer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff2cc;
  margin: 5px auto 8px;
}

.modalChart .moistAnswer a {
  display: block;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: -1.28px;
  text-align: center;
  color: #000000;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  .modalChart .moistAnswer {
    width: 14vw;
    max-width: 105px;
    height: 14vw;
    max-height: 105px;
  }
  .modalChart .moistAnswer a {
    font-size: 1.4rem;
    font-size: 1.02489vw;
    font-feature-settings: "palt" 1;
    letter-spacing: -1.56px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .moistAnswer a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .modalChart .moistAnswer a {
    font-size: 1.86667vw;
  }
}

.modalChart .solidAnswer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  background-color: #d6dce5;
  margin: 5px auto 8px;
}

.modalChart .solidAnswer a {
  display: block;
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1.4;
  letter-spacing: -1.28px;
  text-align: center;
  color: #000000;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  .modalChart .solidAnswer {
    width: 14vw;
    max-width: 105px;
    height: 14vw;
    max-height: 105px;
  }
  .modalChart .solidAnswer a {
    font-size: 1.4rem;
    font-size: 1.02489vw;
    font-feature-settings: "palt" 1;
    letter-spacing: -1.56px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .solidAnswer a {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  .modalChart .solidAnswer a {
    font-size: 1.86667vw;
  }
}

.modalChart .typeTxt {
  max-width: 100%;
  width: 60%;
  margin: 20px auto 0;
}

.modalChart .typeTxt img {
  margin: 0 auto;
}

.modalChart .arrow {
  max-width: 32%;
  width: 100%;
  margin: 5px 0 2px;
}

.modalChart .arrow img {
  max-width: 27px;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 767.9px) {
  .modalChart .arrow img {
    width: 4.93333vw;
    max-width: 37px;
  }
}

.modalChart .chart01,
.modalChart .chart02,
.modalChart .chart03 {
  max-width: 32%;
  width: 100%;
}

.modalChart .chart01 .txt,
.modalChart .chart02 .txt,
.modalChart .chart03 .txt {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 106px;
  border-radius: 10px;
  background-color: #fffef2;
  box-shadow: 3px 2px 17px -3px #888;
}

@media screen and (max-width: 767.9px) {
  .modalChart .chart01 .txt,
  .modalChart .chart02 .txt,
  .modalChart .chart03 .txt {
    height: 16vw;
    max-height: 120px;
    border-radius: 5px;
  }
}

.modalChart .yes {
  max-width: 50%;
  width: 100%;
  margin: 10px 0 2px;
}

.modalChart .yes img {
  max-width: 61px;
  width: 100%;
  margin: 0 45px;
}

@media screen and (max-width: 767.9px) {
  .modalChart .yes img {
    width: 9.46667vw;
    max-width: 71px;
    margin-right: 4vw;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .yes img {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .yes img {
    margin-left: 30px;
  }
}

.modalChart .no {
  max-width: 50%;
  width: 100%;
  margin: 10px 0 2px;
}

.modalChart .no img {
  max-width: 51px;
  width: 100%;
  margin: 0 45px;
}

@media screen and (max-width: 767.9px) {
  .modalChart .no img {
    width: 8.13333vw;
    max-width: 61px;
    margin-right: 4vw;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .no img {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalChart .no img {
    margin-left: 30px;
  }
}

.modalQr {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 901px;
  width: 100%;
  z-index: 99999;
  transition: opacity .4s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modalQr {
    max-width: 95%;
  }
}

.modalQr.isShow {
  opacity: 1;
  visibility: visible;
}

.modalQr .modalQrCon {
  position: relative;
  background-color: #fff;
  border: solid 1px #707070;
  padding-top: 7.32064vw;
  padding-right: 2.92826vw;
  padding-left: 2.92826vw;
  padding-bottom: 6.95461vw;
  max-height: 95vh;
}

@media screen and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-bottom: 95px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .modalQr .modalQrCon {
    padding-top: 13.33333vw;
    padding-bottom: 12.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-top: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-bottom: 95px;
  }
}

@media screen and (max-width: 767.9px) {
  .modalQr .modalQrCon {
    padding-top: 13.33333vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
    padding-bottom: 9.46667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalQr .modalQrCon {
    padding-bottom: 71px;
  }
}

.modalQr button {
  position: absolute;
  top: 25px;
  right: 20px;
  width: 37px;
  height: 37px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.modalQr button:hover {
  transform: rotate(180deg);
  transform-origin: center;
}

.modalQr button span {
  display: block;
  width: 50px;
  height: 3px;
  background-color: #434343;
  position: absolute;
  top: 45%;
  left: -20%;
}

.modalQr button span:nth-of-type(1) {
  transform: rotate(45deg);
}

.modalQr button span:nth-of-type(2) {
  transform: rotate(-45deg);
}

@media screen and (max-width: 767.9px) {
  .modalQr button {
    top: 3.33333vw;
    right: 3.2vw;
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalQr button {
    top: 25px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .modalQr button {
    right: 24px;
  }
}

@media screen and (max-width: 767.9px) {
  .modalQr button span {
    width: 5.73333vw;
    max-width: 43px;
    height: 1px;
  }
}

.modalQr p {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
}

@media screen and (max-width: 767.9px) {
  .modalQr p {
    font-size: 1.4rem;
    line-height: 2;
  }
}

.modalQr .img {
  max-width: 155px;
  width: 100%;
  margin: 0 auto;
  margin-top: 3.29429vw;
}

@media screen and (min-width: 1366px) {
  .modalQr .img {
    margin-top: 45px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: none;
}

.overlay .modalBack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 1;
  cursor: pointer;
}

.overlay .modalMovie {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 1064px;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  z-index: 2;
}

.overlay .modalMovie video {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 767.9px) {
  .overlay .modalMovie {
    top: 20vh;
    transform: translateX(-50%);
  }
}

.overlay .jsMovie {
  position: relative;
}

.overlay .jsMovie.js-movie_active .videoIconPlay {
  opacity: 0;
  pointer-events: none;
}

.overlay .jsMovieButton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 85%;
  cursor: pointer;
  transition: opacity 200ms ease-out;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .overlay .jsMovieButton {
    top: 10%;
    height: 70%;
  }
}

@media screen and (max-width: 767.9px) {
  .overlay .jsMovieButton {
    top: 10%;
    height: 70%;
  }
}

.overlay .videoIcon {
  position: absolute;
  font-size: 20px;
  z-index: 2;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  pointer-events: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .overlay .videoIcon {
    top: 56%;
    width: 8vw;
    max-width: 60px;
    height: 8vw;
    max-height: 60px;
  }
}

@media screen and (max-width: 767.9px) {
  .overlay .videoIcon {
    width: 16vw;
    max-width: 120px;
    height: 16vw;
    max-height: 120px;
  }
}

.overlay button {
  position: relative;
  display: block;
  margin: 0 0 0 auto;
  margin-bottom: 0.73206vw;
  width: 37px;
  height: 37px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

@media screen and (min-width: 1366px) {
  .overlay button {
    margin-bottom: 10px;
  }
}

.overlay button:hover {
  transform: rotate(180deg);
  transform-origin: center;
}

.overlay button span {
  display: block;
  width: 50px;
  height: 3px;
  background-color: #333;
  position: absolute;
  top: 45%;
  left: -20%;
}

.overlay button span:nth-of-type(1) {
  transform: rotate(45deg);
}

.overlay button span:nth-of-type(2) {
  transform: rotate(-45deg);
}

@media screen and (max-width: 767.9px) {
  .overlay button {
    margin: 0 0 0 auto;
    margin-bottom: 2.66667vw;
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  .overlay button {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  .overlay button span {
    width: 5.73333vw;
    max-width: 43px;
    height: 1px;
  }
}

.fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s .6s, transform .5s .5s;
}

.fadeIn.active {
  opacity: 1;
  transform: none;
}

@media screen and (max-width: 767.9px) {
  .fadeIn {
    transform: translateY(10px);
  }
}

.fadeInClickToggleCon {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s .6s, transform .5s .5s;
}

.fadeInClickToggleCon.active {
  opacity: 1;
  transform: none;
}

.fadeInToggleCon {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s .6s, transform .5s .5s;
}

.fadeInToggleCon.active {
  opacity: 1;
  transform: none;
}

/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* =====================================
ヘッダー
===================================== */
#header {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}

@media screen and (max-width: 767.9px) {
  #header {
    position: fixed;
  }
}

#header .headerContents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8.05271vw;
  max-height: 110px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    height: 10.2489vw;
    max-height: 140px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerContents {
    display: block;
    padding: 0;
    height: 16vw;
    max-height: 120px;
  }
}

#header .headerLogo {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 3.58712vw;
}

@media screen and (min-width: 1366px) {
  #header .headerLogo {
    margin-right: 49px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerLogo {
    width: 100%;
    justify-content: center;
    position: relative;
    z-index: 2;
    background-color: #fff;
  }
}

#header .headerLogo a {
  width: 19.39971vw;
  max-width: 265px;
  transition: .5s;
  display: block;
  position: relative;
}

@media screen and (max-width: 767.9px) {
  #header .headerLogo a {
    width: 34.26667vw;
    max-width: 257px;
  }
}

#header .headerLogo img {
  transition: opacity .5s;
}

@media screen and (max-width: 767.9px) {
  #header .headerLogo img {
    width: 100%;
  }
}

#header .navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 5.33333vw;
  width: 17.33333vw;
  max-width: 130px;
  height: 16vw;
  max-height: 120px;
  cursor: pointer;
  z-index: 3;
}

@media screen and (min-width: 1366px) {
  #header .navBtn {
    padding: 40px;
  }
}

#header .navBtn span {
  display: inline-block;
  position: absolute;
  left: 5.33333vw;
  width: 6.66667vw;
  max-width: 50px;
  height: 1px;
  background-color: #000;
  transition: .5s ease-in-out;
}

@media screen and (min-width: 1366px) {
  #header .navBtn span {
    left: 40px;
  }
}

#header .navBtn span:nth-of-type(1) {
  top: 5.33333vw;
}

@media screen and (min-width: 1366px) {
  #header .navBtn span:nth-of-type(1) {
    top: 40px;
  }
}

#header .navBtn span:nth-of-type(2) {
  top: 49%;
}

#header .navBtn span:nth-of-type(3) {
  bottom: 5.33333vw;
}

@media screen and (min-width: 1366px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 40px;
  }
}

#header .navBtn.open span:nth-of-type(1), #header .navBtn.open span:nth-of-type(3) {
  top: 50%;
  background-color: #222;
}

#header .navBtn.open span:nth-of-type(1) {
  transform: rotate(-135deg);
}

#header .navBtn.open span:nth-of-type(2) {
  opacity: 0;
}

#header .navBtn.open span:nth-of-type(3) {
  transform: rotate(135deg);
}

#header .headerNav {
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #header .headerNav {
    position: absolute;
    left: 0;
    max-height: 100vh;
    transform: translateY(-100%);
    z-index: 1;
  }
}

#header .headerNav .otherLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.1713vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerNav .otherLinks {
    margin-bottom: 1.90337vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #header .headerNav .otherLinks {
    margin-bottom: 26px;
  }
}

#header .headerNav .otherLinks ul {
  display: flex;
  align-items: center;
}

#header .headerNav .otherLinks .lang {
  position: relative;
}

#header .headerNav .otherLinks .lang a {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: #231815;
  padding-right: 0.80527vw;
  padding-left: 0.80527vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .lang a {
    padding-right: 11px;
  }
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .lang a {
    padding-left: 11px;
  }
}

#header .headerNav .otherLinks .lang a::after {
  display: block;
  content: "";
  background-image: url(/images/common/lang_triangle.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 0.51245vw;
  max-width: 7px;
  height: 0.43924vw;
  max-height: 6px;
  margin-left: 0.80527vw;
  transition: transform .6s;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .lang a::after {
    margin-left: 11px;
  }
}

#header .headerNav .otherLinks .lang .langChange.open a::after {
  transform: rotate(180deg);
  transform-origin: center;
}

#header .headerNav .otherLinks .lang img {
  width: 1.97657vw;
  max-width: 27px;
  margin-right: 0.29283vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .lang img {
    margin-right: 4px;
  }
}

#header .headerNav .otherLinks .lang .langChangeCon {
  display: none;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  background-color: #fff;
  width: 100%;
}

#header .headerNav .otherLinks .lang .langChangeCon li {
  width: 100%;
}

#header .headerNav .otherLinks .lang .langChangeCon a {
  display: block;
  text-align: center;
  padding-top: 0.36603vw;
  padding-bottom: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .lang .langChangeCon a {
    padding-top: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .lang .langChangeCon a {
    padding-bottom: 5px;
  }
}

#header .headerNav .otherLinks .lang .langChangeCon a::after {
  display: none;
}

#header .headerNav .otherLinks .otherLinksCon {
  border-right: solid 1px #707070;
  line-height: 1.57;
}

#header .headerNav .otherLinks .otherLinksCon:nth-of-type(2) {
  border-left: solid 1px #707070;
}

#header .headerNav .otherLinks .otherLinksCon a {
  display: block;
  font-size: 1.1rem;
  font-weight: normal;
  letter-spacing: normal;
  text-align: left;
  color: #231815;
  padding-right: 0.80527vw;
  padding-left: 0.80527vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .otherLinksCon a {
    padding-right: 11px;
  }
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .otherLinksCon a {
    padding-left: 11px;
  }
}

#header .headerNav .otherLinks .otherLinksCon.red {
  border-right: none;
}

#header .headerNav .otherLinks .otherLinksCon.red a {
  color: #d7053c;
  display: flex;
  align-items: center;
}

#header .headerNav .otherLinks .otherLinksCon.red a::before {
  content: "";
  display: block;
  width: 1.97657vw;
  max-width: 27px;
  height: 1.97657vw;
  max-height: 27px;
  background-image: url(/images/common/icon_store_red.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0.58565vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .otherLinksCon.red a::before {
    margin-right: 8px;
  }
}

#header .headerNav .otherLinks .sns {
  margin-left: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .sns {
    margin-left: 10px;
  }
}

#header .headerNav .otherLinks .sns .snsCon {
  width: 1.97657vw;
  max-width: 27px;
  height: 1.97657vw;
  max-height: 27px;
  margin-left: 0.65886vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .otherLinks .sns .snsCon {
    margin-left: 9px;
  }
}

#header .headerNav .globalNav > ul {
  display: flex;
  align-items: center;
}

#header .headerNav .globalNav .globalNavCon:not(:last-of-type) {
  margin-right: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon:not(:last-of-type) {
    margin-right: 50px;
  }
}

#header .headerNav .globalNav .globalNavCon a {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav {
    max-width: 100%;
    width: 100%;
  }
  #header .headerNav .globalNav > ul {
    max-width: 100%;
    width: 100%;
    height: 100vh;
    display: block;
    padding-top: 4.26667vw;
    padding-bottom: 24.26667vw;
    background-color: #fff;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav > ul {
    padding-top: 32px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav > ul {
    padding-bottom: 182px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon {
    opacity: 0;
  }
  #header .headerNav .globalNav .globalNavCon:not(:last-of-type) {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon:not(:last-of-type) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon:not(:last-of-type) a {
    margin-bottom: 9.86667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon:not(:last-of-type) a {
    margin-bottom: 74px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon a {
    display: block;
    font-size: 1.8rem;
    line-height: 1;
    text-align: center;
  }
  #header .headerNav .globalNav .globalNavCon .langChangeCon {
    display: none;
  }
  #header .headerNav .globalNav .globalNavCon.globalNavConSmall {
    border-top: solid 1px #ccc;
  }
  #header .headerNav .globalNav .globalNavCon.globalNavConSmall a {
    font-size: 1.5rem;
    margin-bottom: 0;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon.globalNavConSmall a {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon.globalNavConSmall a {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon.red {
    background-color: #d7053c;
  }
  #header .headerNav .globalNav .globalNavCon.red a {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2.53333vw;
    padding-bottom: 2.53333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon.red a {
    padding-top: 19px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon.red a {
    padding-bottom: 19px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon.red a::before {
    content: "";
    display: block;
    width: 6.66667vw;
    max-width: 50px;
    height: 6.66667vw;
    max-height: 50px;
    background-image: url(/images/common/icon_store_white.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon.red a::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon .sns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6.4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon .sns {
    margin-top: 48px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .globalNavCon .sns .snsCon {
    width: 8.53333vw;
    max-width: 64px;
    height: 8.53333vw;
    max-height: 64px;
    margin-left: 4vw;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon .sns .snsCon {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .globalNavCon .sns .snsCon {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .lang {
    position: relative;
    margin-top: 2.13333vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang {
    margin-top: 16px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .lang .langChange {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1;
    text-align: center;
    justify-content: center;
    color: #736461;
    padding: 0;
    padding-top: 4vw;
    padding-bottom: 2.8vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChange {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChange {
    padding-bottom: 21px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChange {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .lang .langChange.open::after {
    transform: rotate(180deg);
    transform-origin: center;
  }
  #header .headerNav .globalNav .lang .langChange img {
    width: 6vw;
    max-width: 45px;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChange img {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .lang .langChange::after {
    display: block;
    content: "";
    background-image: url(/images/common/lang_triangle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 2.26667vw;
    max-width: 17px;
    height: 2.13333vw;
    max-height: 16px;
    margin-left: 2vw;
    transition: transform .6s;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChange::after {
    margin-left: 15px;
  }
}

@media screen and (max-width: 767.9px) {
  #header .headerNav .globalNav .lang .langChangeCon a {
    border-top: solid 2px #fff;
    background-color: #f7f7f7;
    padding-top: 3.73333vw;
    padding-bottom: 3.73333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChangeCon a {
    padding-top: 28px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #header .headerNav .globalNav .lang .langChangeCon a {
    padding-bottom: 28px;
  }
}

/* =====================================================
フッター
===================================================== */
#footer {
  background-color: #e2e2e2;
}

#footer .footerCon {
  padding-top: 1.61054vw;
  padding-bottom: 1.68375vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1366px) {
  #footer .footerCon {
    padding-top: 22px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerCon {
    padding-bottom: 23px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerCon {
    padding-top: 8.4vw;
    padding-bottom: 5.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerCon {
    padding-top: 63px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerCon {
    padding-bottom: 42px;
  }
}

#footer .snsLinks {
  display: flex;
  align-items: center;
}

#footer .snsLinks .snsLinksTit {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #231815;
}

#footer .snsLinks .snsLinksCon {
  max-width: 25px;
  margin-left: 1.90337vw;
}

@media screen and (min-width: 1366px) {
  #footer .snsLinks .snsLinksCon {
    margin-left: 26px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .snsLinks .snsLinksCon {
    width: 8.66667vw;
    max-width: 65px;
    margin: 0;
    margin-right: 8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .snsLinks .snsLinksCon {
    margin-right: 60px;
  }
}

#footer .footerLangueage {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767.9px) {
  #footer .footerLangueage {
    justify-content: center;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerLangueage {
    margin-bottom: 60px;
  }
}

#footer .footerLangueage .footerLangueageTit {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #231815;
}

@media screen and (max-width: 767.9px) {
  #footer .footerLangueage .footerLangueageTit {
    font-size: 1.4rem;
    letter-spacing: 1.68px;
    color: #888888;
  }
}

#footer .footerLangueage .footerLangueageCon {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #231815;
  margin-left: 3.3675vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon {
    margin-left: 46px;
  }
}

#footer .footerLangueage .footerLangueageCon span {
  display: flex;
  align-items: center;
  color: #231815;
  border-left: solid 1px #231815;
  border-right: solid 1px #231815;
  padding-left: 3.3675vw;
  padding-right: 1.61054vw;
  cursor: pointer;
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon span {
    padding-left: 46px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon span {
    padding-right: 22px;
  }
}

#footer .footerLangueage .footerLangueageCon span.open::after {
  transform: rotate(180deg);
  transform-origin: center;
}

#footer .footerLangueage .footerLangueageCon span::after {
  content: "";
  display: block;
  width: 1.1713vw;
  max-width: 16px;
  height: 0.58565vw;
  max-height: 8px;
  background-image: url(/images/common/icon_arrow_lang.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 2.48902vw;
  transition: transform .6s;
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon span::after {
    margin-left: 34px;
  }
}

#footer .footerLangueage .footerLangueageCon .langChangeCon {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 1.61054vw;
  background-color: #e2e2e2;
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon {
    bottom: 22px;
  }
}

#footer .footerLangueage .footerLangueageCon .langChangeCon li {
  width: 100%;
}

#footer .footerLangueage .footerLangueageCon .langChangeCon a {
  display: block;
  text-align: center;
  padding-top: 0.36603vw;
  padding-bottom: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon a {
    padding-top: 5px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon a {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon {
    bottom: 2.93333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon {
    bottom: 22px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerLangueage .footerLangueageCon {
    font-size: 1.4rem;
    letter-spacing: 1.68px;
    margin-left: 6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon {
    margin-left: 45px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon {
    bottom: 5.86667vw;
    background-color: #fff;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon .langChangeCon {
    bottom: 44px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerLangueage .footerLangueageCon span {
    border-left: solid 1px #c9c9c9;
    border-right: none;
    padding-left: 6vw;
    padding-right: 6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon span {
    padding-left: 45px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon span {
    padding-right: 45px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerLangueage .footerLangueageCon span::after {
    width: 4.66667vw;
    max-width: 35px;
    height: 1.86667vw;
    max-height: 14px;
    margin-left: 6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerLangueage .footerLangueageCon span::after {
    margin-left: 45px;
  }
}

#footer .pageTop {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: left;
  color: #333;
  cursor: pointer;
}

#footer .pageTop::before {
  content: "";
  display: block;
  width: 2.66667vw;
  max-width: 20px;
  height: 2.66667vw;
  max-height: 20px;
  border-top: solid 2px #333;
  border-left: solid 2px #333;
  transform: rotate(45deg);
  margin: 0 auto 2px;
}

#footer .footerCopyright {
  background-color: #fff;
  padding-top: 2.70864vw;
  padding-bottom: 3.29429vw;
  padding-left: 9.15081vw;
  padding-right: 9.15081vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 1366px) {
  #footer .footerCopyright {
    padding-top: 37px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerCopyright {
    padding-bottom: 45px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerCopyright {
    padding-left: 125px;
  }
}

@media screen and (min-width: 1366px) {
  #footer .footerCopyright {
    padding-right: 125px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerCopyright {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerCopyright {
    display: block;
    padding-top: 8vw;
    padding-bottom: 18.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerCopyright {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerCopyright {
    padding-bottom: 137px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerCopyright .footerNishikawaLogo {
    width: 38.8vw;
    max-width: 291px;
    margin: 0 auto 9px;
    margin-top: 9.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerCopyright .footerNishikawaLogo {
    margin-top: 70px;
  }
}

@media screen and (max-width: 767.9px) {
  #footer .footerCopyright .footerNishikawaLogo img {
    width: 100%;
  }
}

#footer .footerCopyright .copyrightBox {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767.9px) {
  #footer .footerCopyright .copyrightBox {
    display: block;
  }
}

#footer .footerCopyright .copyright {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
  color: #707070;
  padding: 0 0 0 42px;
}

@media screen and (max-width: 767.9px) {
  #footer .footerCopyright .copyright {
    display: block;
    padding: 0;
  }
}

#footer .footerCopyright .ContactBox a {
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  text-decoration: underline;
  color: #58595b;
}

@media screen and (max-width: 767.9px) {
  #footer .footerCopyright .ContactBox {
    text-align: center;
    margin-top: 7.46667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #footer .footerCopyright .ContactBox {
    margin-top: 56px;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop {
  padding-top: 8.05271vw;
}

@media screen and (min-width: 1366px) {
  #indexTop {
    padding-top: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop {
    padding-top: 10.2489vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop {
    padding-top: 140px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop {
    padding-top: 16vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop {
    padding-top: 120px;
  }
}

#indexTop .keyVisual {
  display: block;
  position: relative;
}

#indexTop .keyVisualSlider {
  height: 40.26354vw;
  max-height: 550px;
  max-height: none;
  opacity: 0;
  transition: opacity .3s linear;
}

#indexTop .keyVisualSlider.slick-initialized {
  opacity: 1;
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider {
    height: 78.8vw;
    max-height: 591px;
  }
}

#indexTop .keyVisualSlider .item {
  height: 40.26354vw;
  max-height: 550px;
  max-height: none;
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .item {
    height: 78.8vw;
    max-height: 591px;
  }
}

#indexTop .keyVisualSlider .slick-list {
  overflow: hidden;
}

#indexTop .keyVisualSlider .slick-arrow {
  position: absolute;
  width: 23px;
  height: 72px;
  color: rgba(255, 255, 255, 0);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(255, 255, 255, 0);
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

#indexTop .keyVisualSlider .slick-arrow.slick-prev {
  background-image: url(/images/common/slide_back_arrow.svg);
  left: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slick-arrow.slick-prev {
    left: 40px;
  }
}

#indexTop .keyVisualSlider .slick-arrow.slick-next {
  background-image: url(/images/common/slide_next_arrow.svg);
  right: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slick-arrow.slick-next {
    right: 40px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .slick-arrow {
    opacity: 0;
    visibility: hidden;
  }
}

#indexTop .keyVisualSlider .slideBanner {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  max-width: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .keyVisualSlider .slideBanner {
    bottom: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .slideBanner {
    bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slideBanner {
    bottom: 30px;
  }
}

#indexTop .keyVisualSlider .slideBanner li {
  flex: 1;
  height: 1.75695vw;
  max-height: 24px;
  display: flex;
  margin-right: 1.83016vw;
  margin-left: 1.83016vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slideBanner li {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slideBanner li {
    margin-left: 25px;
  }
}

#indexTop .keyVisualSlider .slideBanner li button {
  width: 1.75695vw;
  max-width: 24px;
  color: rgba(255, 255, 255, 0);
}

#indexTop .keyVisualSlider .slideBanner li button::before {
  content: "";
  height: 1.75695vw;
  max-height: 24px;
  border-radius: 100%;
  background-color: #c6c2c2;
  display: block;
}

#indexTop .keyVisualSlider .slideBanner li:not(.slick-active) button::before {
  width: 1.75695vw;
  max-width: 24px;
  background-color: #fff;
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .slideBanner li {
    height: 3.2vw;
    max-height: 24px;
    margin-right: 3.33333vw;
    margin-left: 3.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slideBanner li {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisualSlider .slideBanner li {
    margin-left: 25px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .slideBanner li button {
    width: 3.2vw;
    max-width: 24px;
  }
  #indexTop .keyVisualSlider .slideBanner li button::before {
    height: 3.2vw;
    max-height: 24px;
  }
  #indexTop .keyVisualSlider .slideBanner li:not(.slick-active) button::before {
    width: 3.2vw;
    max-width: 24px;
  }
}

#indexTop .keyVisualSlider .slideBanner .slick-active button::before {
  width: 1.75695vw;
  max-width: 24px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .slideBanner .slick-active button::before {
    width: 3.2vw;
    max-width: 24px;
  }
}

#indexTop .keyVisualSlider .video {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

#indexTop .keyVisualSlider .video video {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .video video {
    width: auto;
    height: 100%;
  }
}

#indexTop .keyVisualSlider .video video::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
}

#indexTop .keyVisualSlider .video a {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1002;
}

#indexTop .keyVisualSlider .video .playAll {
  position: absolute;
  bottom: 2.19619vw;
  left: 10.2489vw;
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisualSlider .video .playAll {
    bottom: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisualSlider .video .playAll {
    left: 140px;
  }
}

#indexTop .keyVisualSlider .video .playAll::before {
  content: "";
  display: inline-block;
  vertical-align: sub;
  width: 27px;
  height: 27px;
  margin: 0 17px 0 0;
  background-image: url(/images/common/icon_play.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .video .playAll {
    bottom: 12.26667vw;
    left: 7.33333vw;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisualSlider .video .playAll {
    bottom: 92px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisualSlider .video .playAll {
    left: 55px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .video .playAll::before {
    width: 6.66667vw;
    max-width: 50px;
    height: 6.66667vw;
    max-height: 50px;
    margin-right: 2.53333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisualSlider .video .playAll::before {
    margin-right: 19px;
  }
}

#indexTop .keyVisualSlider .image {
  position: relative;
  overflow: hidden;
}

#indexTop .keyVisualSlider .image img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisualSlider .image img {
    width: auto;
    height: 100%;
  }
}

#indexTop .keyVisualSlider .image img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
}

#indexTop .keyVisual .scroll {
  position: absolute;
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  writing-mode: vertical-rl;
  bottom: -10.98097vw;
  right: 6.58858vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisual .scroll {
    bottom: -150px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .keyVisual .scroll {
    right: 90px;
  }
}

#indexTop .keyVisual .scroll::after {
  content: "";
  display: block;
  width: 0.07321vw;
  max-width: 1px;
  height: 18.59444vw;
  max-height: 254px;
  background-color: #707070;
  margin: 0 10px 0 0;
  animation: pathmove 2.5s ease-in-out infinite;
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisual .scroll {
    font-size: 3.1rem;
    font-size: 2.2694vw;
    bottom: -39.2vw;
    right: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisual .scroll {
    font-size: 3.1rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .keyVisual .scroll {
    font-size: 4.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisual .scroll {
    bottom: -294px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .keyVisual .scroll {
    right: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .keyVisual .scroll::after {
    height: 59.6vw;
    max-height: 447px;
  }
}

@keyframes pathmove {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

#indexTop .message {
  background-image: url(/images/message/message_back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 3.87994vw;
  padding-bottom: 10.10249vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .message {
    padding-top: 53px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .message {
    padding-bottom: 138px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .message {
    padding-top: 12.8vw;
    padding-bottom: 19.73333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message {
    padding-top: 96px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message {
    padding-bottom: 148px;
  }
}

#indexTop .message .container:nth-of-type(2) {
  display: none;
}

#indexTop .message .messageTit {
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0.7px;
  color: #736461;
  margin-bottom: 3.22108vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .message .messageTit {
    margin-bottom: 44px;
  }
}

#indexTop .message .messageTit span {
  font-size: 1.8rem;
  vertical-align: top;
  margin-left: -12px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .message .messageTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    letter-spacing: 0.9px;
    margin-bottom: 9.06667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .message .messageTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTit {
    margin-bottom: 68px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .message .messageTit span {
    font-size: 2.6rem;
    font-size: 1.90337vw;
    margin-left: -2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTit span {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .message .messageTit span {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTit span {
    margin-left: -20px;
  }
}

#indexTop .message .messageTxt {
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  line-height: 2;
  letter-spacing: 0.6px;
  color: #736461;
  height: 288px;
}

#indexTop .message .messageTxt .annotation {
  display: block;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: right;
  color: #736461;
  max-width: 584px;
  margin: 20px auto 0;
}

@media screen and (min-width: 812px) and (max-width: 940px) {
  #indexTop .message .messageTxt {
    height: 336px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .message .messageTxt {
    height: 432px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .message .messageTxt {
    font-size: 3rem;
    font-size: 2.19619vw;
    height: 70.8vw;
    max-height: 531px;
    letter-spacing: 0.8px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTxt {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .message .messageTxt {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .message .messageTxt .annotation {
    font-size: 2.5rem;
    font-size: 1.83016vw;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTxt .annotation {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .message .messageTxt .annotation {
    font-size: 3.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .message .messageTxt .annotation {
    margin-top: 40px;
  }
}

#indexTop .concept {
  background: radial-gradient(at left top, #f7f7f7 0%, #e1e4e4 51%, #d8d8d8 71%, #d2d5d9 78%, #dddddd 100%);
  background-size: 100%;
  padding-top: 6.88141vw;
  padding-bottom: 9.51684vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept {
    padding-top: 94px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept {
    padding-top: 9.06667vw;
    padding-bottom: 17.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept {
    padding-top: 68px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept {
    padding-bottom: 130px;
  }
}

#indexTop .concept .conceptTitBlock {
  text-align: center;
}

#indexTop .concept .conceptTitBlock .conceptLead {
  max-width: 720px;
  margin: 0 auto;
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: 1.96px;
  color: #736461;
  border-bottom: solid 1px #736461;
  padding-bottom: 0.73206vw;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    width: 89.33333vw;
    max-width: 670px;
    font-size: 3.6rem;
    font-size: 2.63543vw;
    letter-spacing: 2.52px;
    padding-bottom: 1.33333vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptLead {
    margin-bottom: 40px;
  }
}

#indexTop .concept .conceptTitBlock .conceptTit {
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.69;
  letter-spacing: 2.52px;
  color: #736461;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptTitBlock .conceptTit {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptTitBlock .conceptTit {
    font-size: 4.2rem;
    font-size: 3.07467vw;
    letter-spacing: 2.94px;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptTit {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptTitBlock .conceptTit {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptTitBlock .conceptTit {
    margin-bottom: 30px;
  }
}

#indexTop .concept .conceptMainImg {
  max-width: 853px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 4.24597vw;
  position: relative;
  overflow-x: hidden;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg {
    margin-bottom: 58px;
  }
}

#indexTop .concept .conceptMainImg .conceptMainImgPart01 {
  position: absolute;
  max-width: 228px;
  top: 124px;
  right: calc(50% - 322px);
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: all 0.4s ease-out;
}

#indexTop .concept .conceptMainImg .conceptMainImgPart01.isShow {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart01 {
    width: 26.8vw;
    max-width: 201px;
    right: 13.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart01 {
    right: 100px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart01 {
    width: 33.46667vw;
    max-width: 251px;
    top: 14.26667vw;
    right: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart01 {
    top: 107px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart01 {
    right: 30px;
  }
}

#indexTop .concept .conceptMainImg .conceptMainImgPart02 {
  position: absolute;
  max-width: 256px;
  bottom: 156px;
  left: 14px;
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
  transition: all 0.4s ease-out;
}

#indexTop .concept .conceptMainImg .conceptMainImgPart02.isShow {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart02 {
    width: 30vw;
    max-width: 225px;
    bottom: 137px;
    left: 3.33333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart02 {
    left: 25px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart02 {
    width: 30vw;
    max-width: 225px;
    bottom: 18.26667vw;
    left: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart02 {
    bottom: 137px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart02 {
    left: 10px;
  }
}

#indexTop .concept .conceptMainImg .conceptMainImgPart03 {
  position: absolute;
  max-width: 159px;
  bottom: 36px;
  right: calc(50% - 146px);
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: all 0.4s ease-out;
}

#indexTop .concept .conceptMainImg .conceptMainImgPart03.isShow {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart03 {
    width: 18.66667vw;
    max-width: 140px;
    bottom: 3.46667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart03 {
    bottom: 26px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart03 {
    width: 18.66667vw;
    max-width: 140px;
    bottom: 2.93333vw;
    right: 30.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart03 {
    bottom: 22px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg .conceptMainImgPart03 {
    right: 230px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMainImg {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMainImg {
    margin-bottom: 60px;
  }
}

#indexTop .concept .conceptLinks {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6.29575vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptLinks {
    margin-bottom: 86px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptLinks {
    flex-wrap: wrap;
    margin-bottom: 9.73333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptLinks {
    margin-bottom: 73px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptLinks li:not(:last-of-type) {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptLinks li:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

#indexTop .concept video {
  max-width: 100%;
  width: 100%;
  height: auto;
}

#indexTop .concept .conceptConstruction {
  overflow: hidden;
  margin-bottom: 4.90483vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction {
    margin-bottom: 67px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction {
    margin-bottom: 14.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction {
    margin-bottom: 106px;
  }
}

#indexTop .concept .conceptConstruction .container {
  position: relative;
  z-index: 1;
  padding-bottom: 9.80966vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .container {
    padding-bottom: 134px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .container {
    padding-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .container {
    padding-bottom: 100px;
  }
}

#indexTop .concept .conceptConstruction .container::after {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 60px;
  left: 20px;
  z-index: -1;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .container::after {
    top: 22vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .container::after {
    top: 165px;
  }
}

#indexTop .concept .conceptConstruction .conceptConstructionTit {
  width: 47.21816vw;
  max-width: 645px;
  margin-bottom: 4.68521vw;
  padding-left: 6.73499vw;
  padding-bottom: 0.65886vw;
  border-bottom: solid 1px #707070;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    padding-left: 92px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    padding-bottom: 9px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    width: 50vw;
    max-width: 375px;
    padding-left: 7.2vw;
    padding-right: 7.6vw;
    padding-bottom: 1.33333vw;
    margin-bottom: 7.33333vw;
    margin-left: -20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    padding-left: 54px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    padding-right: 57px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionTit {
    margin-bottom: 55px;
  }
}

#indexTop .concept .conceptConstruction .flexColumn {
  display: flex;
  justify-content: space-between;
  padding-left: 6.00293vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn {
    padding-left: 82px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn {
    flex-wrap: wrap;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn {
    padding-left: 0px;
  }
}

#indexTop .concept .conceptConstruction .flexColumn .movieColumn {
  position: relative;
  max-width: 464px;
  width: 100%;
  padding: 0 10px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .movieColumn {
    max-width: 100%;
    padding: 0;
  }
}

#indexTop .concept .conceptConstruction .flexColumn .txtColumn {
  position: relative;
  max-width: 464px;
  width: 100%;
  padding: 0 10px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtColumn {
    max-width: 100%;
    padding: 0;
  }
}

#indexTop .concept .conceptConstruction .flexColumn .txt {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn .txt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txt {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 2.66667vw;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn .txt {
    margin-bottom: 20px;
  }
}

#indexTop .concept .conceptConstruction .flexColumn .txtLead {
  display: flex;
  align-items: center;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead {
    margin-bottom: 20px;
  }
}

#indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(1) {
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.6rem;
  font-weight: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: #736461;
}

#indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(2) {
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: 1.26px;
  color: #736461;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(1) {
    font-size: 3.4rem;
  }
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(2) {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead {
    margin-bottom: 4.66667vw;
    padding-left: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(1) {
    font-size: 6.9rem;
    font-size: 5.05124vw;
    line-height: 0.811;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(1) {
    font-size: 6.9rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(1) {
    font-size: 9.2vw;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(2) {
    font-size: 2.5rem;
    font-size: 1.83016vw;
    line-height: 1.18;
    letter-spacing: 1.96px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(2) {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .flexColumn .txtLead span:nth-of-type(2) {
    font-size: 3.33333vw;
  }
}

#indexTop .concept .conceptConstruction .conceptConstructionCon01 {
  margin-bottom: 4.97804vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 {
    margin-bottom: 68px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 {
    margin-bottom: 30px;
  }
}

#indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn {
  order: 2;
}

#indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn video {
  position: absolute;
  top: -100px;
  right: -40px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn video {
    top: -8vw;
    right: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn video {
    top: -60px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn {
    order: 1;
    margin-left: -20px;
    margin-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 .movieColumn video {
    width: 100%;
    position: static;
  }
}

#indexTop .concept .conceptConstruction .conceptConstructionCon01 .txtColumn {
  order: 1;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon01 .txtColumn {
    order: 2;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon02 .movieColumn img {
    width: 100%;
  }
}

#indexTop .concept .conceptConstruction .conceptConstructionCon02 .txt {
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon02 .txt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon02 .txt {
    margin-top: 4.66667vw;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon02 .txt {
    margin-top: 35px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptConstruction .conceptConstructionCon02 .txt {
    margin-bottom: 35px;
  }
}

#indexTop .concept .conceptMaterial {
  overflow: hidden;
}

#indexTop .concept .conceptMaterial .container {
  position: relative;
  z-index: 1;
  padding-bottom: 3.29429vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .container {
    padding-bottom: 45px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .container {
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .container {
    padding-bottom: 35px;
  }
}

#indexTop .concept .conceptMaterial .container::after {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 67px;
  right: 20px;
  z-index: -1;
}

#indexTop .concept .conceptMaterial .conceptMaterialTit {
  width: 51.97657vw;
  max-width: 710px;
  margin: 0 0 0 auto;
  margin-bottom: 4.24597vw;
  padding-right: 3.66032vw;
  padding-bottom: 1.0981vw;
  border-bottom: solid 1px #707070;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    margin-bottom: 58px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    padding-bottom: 15px;
  }
}

#indexTop .concept .conceptMaterial .conceptMaterialTit img {
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    margin: 0;
    margin-left: -20px;
    width: 49.86667vw;
    max-width: 374px;
    padding-left: 9.73333vw;
    padding-right: 11.06667vw;
    padding-bottom: 1.33333vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    padding-left: 73px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    padding-right: 83px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .conceptMaterialTit {
    margin-bottom: 20px;
  }
}

#indexTop .concept .conceptMaterial .flexColumn {
  display: flex;
  justify-content: space-between;
  padding-right: 5.27086vw;
  margin-bottom: 3.3675vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn {
    padding-right: 72px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn {
    margin-bottom: 46px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .flexColumn {
    flex-wrap: wrap;
    padding-right: 0vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn {
    margin-bottom: 30px;
  }
}

#indexTop .concept .conceptMaterial .flexColumn .movieColumn {
  position: relative;
  max-width: 464px;
  width: 100%;
  padding: 0 10px;
}

#indexTop .concept .conceptMaterial .flexColumn .movieColumn video {
  position: absolute;
  top: -100px;
  left: 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptMaterial .flexColumn .movieColumn video {
    top: -8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn .movieColumn video {
    top: -60px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .flexColumn .movieColumn {
    max-width: 100%;
    padding: 0;
    margin-right: -20px;
    margin-left: 20px;
    margin-bottom: 9.73333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn .movieColumn {
    margin-bottom: 73px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .flexColumn .movieColumn video {
    width: 100%;
    position: static;
  }
}

#indexTop .concept .conceptMaterial .flexColumn .txtColumn {
  position: relative;
  max-width: 464px;
  width: 100%;
  padding: 0 10px 0 20px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .flexColumn .txtColumn {
    max-width: 100%;
    padding: 0 10px;
  }
}

#indexTop .concept .conceptMaterial .flexColumn .txt {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn .txt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMaterial .flexColumn .txt {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 2.66667vw;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMaterial .flexColumn .txt {
    margin-bottom: 20px;
  }
}

#indexTop .concept .conceptMaterial .btn02 {
  margin: 0 auto;
}

#indexTop .concept .conceptSoft {
  overflow: hidden;
}

#indexTop .concept .conceptSoft .max-container {
  position: relative;
  z-index: 1;
  padding-top: 3.95315vw;
  padding-bottom: 1.31772vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .max-container {
    padding-top: 54px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .max-container {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1146px) and (max-width: 1365px) {
  #indexTop .concept .conceptSoft .max-container {
    max-width: 1128px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .max-container {
    padding-top: 7.33333vw;
    padding-bottom: 12.4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .max-container {
    padding-top: 55px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .max-container {
    padding-bottom: 93px;
  }
}

#indexTop .concept .conceptSoft .max-container::after {
  content: "";
  display: block;
  background-image: url(/images/concept/soft_back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 20px;
  z-index: -1;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .max-container::after {
    right: 8.63836vw;
  }
}

@media screen and (min-width: 1366px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .max-container::after {
    right: 118px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftTit {
  max-width: 609px;
  width: 100%;
  padding-right: 11.347vw;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftTit {
    padding-right: 155px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftTit span {
  display: block;
  font-size: 2.8rem;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -2.24px;
  text-align: left;
  color: #ffffff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptSoft .conceptSoftTit {
    margin-bottom: 4.93333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftTit {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftTit {
    width: 67.73333vw;
    max-width: 508px;
    padding-right: 0vw;
    margin: 0;
    margin-bottom: 7.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftTit {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftTit {
    margin-bottom: 57px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftTit span {
    font-size: 3.1rem;
    font-size: 2.2694vw;
    letter-spacing: -2.48px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftTit span {
    font-size: 3.1rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftTit span {
    font-size: 4.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftTit span {
    margin-bottom: 10px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftCon {
  padding-right: 18.08199vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftCon {
    padding-right: 247px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftCon::after {
  display: block;
  clear: both;
  content: "";
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptSoft .conceptSoftCon {
    padding-right: 8vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftCon {
    padding-right: 60px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftCon {
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftCon {
    padding-right: 40px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftCon .left {
  float: left;
  width: 100%;
}

#indexTop .concept .conceptSoft .conceptSoftCon .right {
  float: right;
}

#indexTop .concept .conceptSoft .conceptSoftConTit {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: normal;
  color: #736461;
}

#indexTop .concept .conceptSoft .conceptSoftConTit img {
  display: inline;
  vertical-align: sub;
  margin-left: 15px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftConTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    line-height: 1.64;
    letter-spacing: -1.28px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftConTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftConTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftConTit img {
    width: 6.66667vw;
    max-width: 50px;
    height: 6.66667vw;
    max-height: 50px;
    margin-left: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftConTit img {
    margin-left: 10px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftConTxt {
  font-size: 1.8rem;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftConTxt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftConTxt {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .conceptSoftConTxt {
    margin-bottom: 10px;
  }
}

#indexTop .concept .conceptSoft .conceptSoftConLinks a {
  font-size: 1.8rem;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .conceptSoftConLinks a {
    font-size: 1.2rem;
    line-height: 2;
  }
}

#indexTop .concept .conceptSoft .flexColumn {
  display: flex;
  margin-bottom: 7.32064vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .flexColumn {
    margin-bottom: 100px;
  }
}

#indexTop .concept .conceptSoft .flexColumn .imgColumn {
  position: relative;
  max-width: 160px;
  width: 100%;
  padding-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .flexColumn .imgColumn {
    padding-right: 10px;
  }
}

#indexTop .concept .conceptSoft .flexColumn .imgColumn img {
  box-shadow: 0 10px 10px 0 rgba(57, 55, 55, 0.34);
  border-radius: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .flexColumn .imgColumn {
    width: 20vw;
    max-width: 150px;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .flexColumn .imgColumn {
    padding-right: 0px;
  }
}

#indexTop .concept .conceptSoft .flexColumn .txtColumn {
  position: relative;
  max-width: 353px;
  width: 100%;
  padding-left: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .flexColumn .txtColumn {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSoft .flexColumn .txtColumn {
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSoft .flexColumn .txtColumn {
    padding-left: 20px;
  }
}

#indexTop .concept .conceptMoist {
  overflow: hidden;
}

#indexTop .concept .conceptMoist .max-container {
  position: relative;
  z-index: 1;
  padding-top: 3.95315vw;
  padding-bottom: 3.87994vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .max-container {
    padding-top: 54px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .max-container {
    padding-bottom: 53px;
  }
}

@media screen and (min-width: 1146px) and (max-width: 1365px) {
  #indexTop .concept .conceptMoist .max-container {
    max-width: 1128px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .max-container {
    padding-top: 7.73333vw;
    padding-bottom: 14.4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .max-container {
    padding-top: 58px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .max-container {
    padding-bottom: 108px;
  }
}

#indexTop .concept .conceptMoist .max-container::after {
  content: "";
  display: block;
  background-image: url(/images/concept/moist_back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 20px;
  z-index: -1;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .max-container::after {
    right: 8.63836vw;
  }
}

@media screen and (min-width: 1366px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .max-container::after {
    right: 118px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistTit {
  max-width: 609px;
  width: 100%;
  padding-right: 11.347vw;
  margin-bottom: 3.80673vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    padding-right: 155px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    margin-bottom: 52px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistTit span {
  display: block;
  font-size: 2.8rem;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -2.24px;
  text-align: left;
  color: #736461;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    margin-bottom: 4.93333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    width: 67.73333vw;
    max-width: 508px;
    padding-right: 0vw;
    margin: 0;
    margin-bottom: 7.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit {
    margin-bottom: 57px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistTit span {
    font-size: 3.1rem;
    font-size: 2.2694vw;
    letter-spacing: -2.48px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit span {
    font-size: 3.1rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistTit span {
    font-size: 4.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistTit span {
    margin-bottom: 10px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistCon {
  padding-right: 10.46852vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistCon {
    padding-right: 143px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistCon::after {
  display: block;
  clear: both;
  content: "";
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistCon {
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistCon {
    padding-right: 40px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistCon .left {
  float: left;
}

#indexTop .concept .conceptMoist .conceptMoistCon .right {
  float: right;
  margin-top: 6.58858vw;
  margin-bottom: 6.58858vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistCon .right {
    margin-top: 90px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistCon .right {
    margin-bottom: 90px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistCon .right .txtColumn {
  max-width: 363px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistCon .right {
    margin-top: 10.93333vw;
    margin-bottom: 10.93333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistCon .right {
    margin-top: 82px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistCon .right {
    margin-bottom: 82px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistCon .center {
  clear: both;
  justify-content: center;
  margin: 0 auto;
}

#indexTop .concept .conceptMoist .conceptMoistCon .center .txtColumn {
  max-width: 477px;
}

#indexTop .concept .conceptMoist .conceptMoistConTit {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: normal;
  color: #736461;
}

#indexTop .concept .conceptMoist .conceptMoistConTit img {
  display: inline;
  vertical-align: sub;
  margin-left: 15px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistConTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    line-height: 1.64;
    letter-spacing: -1.28px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistConTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistConTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistConTit img {
    width: 6.66667vw;
    max-width: 50px;
    height: 6.66667vw;
    max-height: 50px;
    margin-left: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistConTit img {
    margin-left: 10px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistConTxt {
  font-size: 1.8rem;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistConTxt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistConTxt {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .conceptMoistConTxt {
    margin-bottom: 10px;
  }
}

#indexTop .concept .conceptMoist .conceptMoistConLinks a {
  font-size: 1.8rem;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .conceptMoistConLinks a {
    font-size: 1.2rem;
    line-height: 2;
  }
}

#indexTop .concept .conceptMoist .flexColumn {
  display: flex;
}

#indexTop .concept .conceptMoist .flexColumn .imgColumn {
  position: relative;
  max-width: 160px;
  width: 100%;
  padding-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .flexColumn .imgColumn {
    padding-right: 10px;
  }
}

#indexTop .concept .conceptMoist .flexColumn .imgColumn img {
  box-shadow: 0 10px 10px 0 rgba(57, 55, 55, 0.34);
  border-radius: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .flexColumn .imgColumn {
    width: 20vw;
    max-width: 150px;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .flexColumn .imgColumn {
    padding-right: 0px;
  }
}

#indexTop .concept .conceptMoist .flexColumn .txtColumn {
  position: relative;
  max-width: 353px;
  width: 100%;
  padding-left: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .flexColumn .txtColumn {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptMoist .flexColumn .txtColumn {
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptMoist .flexColumn .txtColumn {
    padding-left: 20px;
  }
}

#indexTop .concept .conceptSolid {
  overflow: hidden;
}

#indexTop .concept .conceptSolid .max-container {
  position: relative;
  z-index: 1;
  padding-top: 1.61054vw;
  padding-bottom: 1.61054vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .max-container {
    padding-top: 22px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .max-container {
    padding-bottom: 22px;
  }
}

@media screen and (min-width: 1146px) and (max-width: 1365px) {
  #indexTop .concept .conceptSolid .max-container {
    max-width: 1128px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .max-container {
    padding-top: 7.6vw;
    padding-bottom: 8.4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .max-container {
    padding-top: 57px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .max-container {
    padding-bottom: 63px;
  }
}

#indexTop .concept .conceptSolid .max-container::after {
  content: "";
  display: block;
  background-image: url(/images/concept/solid_back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 20px;
  z-index: -1;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .max-container::after {
    right: 8.63836vw;
  }
}

@media screen and (min-width: 1366px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .max-container::after {
    right: 118px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidTit {
  max-width: 609px;
  width: 100%;
  margin: 0 0 0 auto;
  padding-right: 11.347vw;
  margin-bottom: 2.34261vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    padding-right: 155px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    margin-bottom: 32px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidTit span {
  display: block;
  font-size: 2.8rem;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: -2.24px;
  text-align: left;
  color: #736461;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    margin-bottom: 4.93333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    margin-bottom: 37px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    width: 67.73333vw;
    max-width: 508px;
    padding-right: 0vw;
    margin: 0;
    margin-bottom: 7.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit {
    margin-bottom: 57px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidTit span {
    font-size: 3.1rem;
    font-size: 2.2694vw;
    letter-spacing: -2.48px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit span {
    font-size: 3.1rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidTit span {
    font-size: 4.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidTit span {
    margin-bottom: 10px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidCon {
  padding-right: 10.46852vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidCon {
    padding-right: 143px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidCon::after {
  display: block;
  clear: both;
  content: "";
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidCon {
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidCon {
    padding-right: 40px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidCon .left {
  float: left;
}

#indexTop .concept .conceptSolid .conceptSolidCon .left .txtColumn {
  max-width: 417px;
}

#indexTop .concept .conceptSolid .conceptSolidCon .right {
  float: right;
  margin-top: 9.88287vw;
  margin-bottom: 4.97804vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidCon .right {
    margin-top: 135px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidCon .right {
    margin-bottom: 68px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidCon .right .txtColumn {
  max-width: 363px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidCon .right {
    margin-top: 11.2vw;
    margin-bottom: 15.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidCon .right {
    margin-top: 84px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidCon .right {
    margin-bottom: 115px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidCon .center {
  clear: both;
  justify-content: center;
  margin: 0 auto;
}

#indexTop .concept .conceptSolid .conceptSolidCon .center .txtColumn {
  max-width: 629px;
}

#indexTop .concept .conceptSolid .conceptSolidConTit {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: normal;
  color: #736461;
}

#indexTop .concept .conceptSolid .conceptSolidConTit img {
  display: inline;
  vertical-align: sub;
  margin-left: 15px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidConTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    line-height: 1.64;
    letter-spacing: -1.28px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidConTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidConTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidConTit img {
    width: 6.66667vw;
    max-width: 50px;
    height: 6.66667vw;
    max-height: 50px;
    margin-left: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidConTit img {
    margin-left: 10px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidConTxt {
  font-size: 1.8rem;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidConTxt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidConTxt {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .conceptSolidConTxt {
    margin-bottom: 10px;
  }
}

#indexTop .concept .conceptSolid .conceptSolidConLinks a {
  font-size: 1.8rem;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .conceptSolidConLinks a {
    font-size: 1.2rem;
    line-height: 2;
  }
}

#indexTop .concept .conceptSolid .flexColumn {
  display: flex;
}

#indexTop .concept .conceptSolid .flexColumn .imgColumn {
  position: relative;
  max-width: 160px;
  width: 100%;
  padding-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .flexColumn .imgColumn {
    padding-right: 10px;
  }
}

#indexTop .concept .conceptSolid .flexColumn .imgColumn img {
  box-shadow: 0 10px 10px 0 rgba(57, 55, 55, 0.34);
  border-radius: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .flexColumn .imgColumn {
    width: 20vw;
    max-width: 150px;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .flexColumn .imgColumn {
    padding-right: 0px;
  }
}

#indexTop .concept .conceptSolid .flexColumn .txtColumn {
  position: relative;
  max-width: 353px;
  width: 100%;
  padding-left: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .flexColumn .txtColumn {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptSolid .flexColumn .txtColumn {
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptSolid .flexColumn .txtColumn {
    padding-left: 20px;
  }
}

#indexTop .concept .conceptFabric {
  overflow: hidden;
  margin-top: 6.14934vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric {
    margin-top: 84px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric {
    margin-top: 9.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric {
    margin-top: 70px;
  }
}

#indexTop .concept .conceptFabric .container {
  position: relative;
  z-index: 1;
  padding-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .container {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .container {
    padding-bottom: 9.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .container {
    padding-bottom: 72px;
  }
}

#indexTop .concept .conceptFabric .container::after {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 65px;
  left: 20px;
  z-index: -1;
}

#indexTop .concept .conceptFabric .conceptFabriclTit {
  width: 47.21816vw;
  max-width: 645px;
  margin-bottom: 5.6369vw;
  padding-left: 2.34261vw;
  padding-bottom: 0.73206vw;
  border-bottom: solid 1px #707070;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    margin-bottom: 77px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    padding-left: 32px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    width: 49.86667vw;
    max-width: 374px;
    padding-left: 10.13333vw;
    padding-right: 14.4vw;
    padding-bottom: 1.33333vw;
    margin-bottom: 5.86667vw;
    margin-left: -20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    padding-left: 76px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    padding-right: 108px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .conceptFabriclTit {
    margin-bottom: 44px;
  }
}

#indexTop .concept .conceptFabric .flexColumn {
  display: flex;
  justify-content: space-between;
  padding-left: 2.34261vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn {
    padding-left: 32px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .flexColumn {
    flex-wrap: wrap;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn {
    padding-left: 0px;
  }
}

#indexTop .concept .conceptFabric .flexColumn .movieColumn {
  position: relative;
  max-width: 464px;
  width: 100%;
  padding: 0 10px;
  order: 2;
}

#indexTop .concept .conceptFabric .flexColumn .movieColumn .movie01 {
  position: absolute;
  top: -8.05271vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie01 {
    top: -110px;
  }
}

#indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 {
  max-width: 168px;
  margin-top: 12.4451vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 {
    margin-top: 170px;
  }
}

#indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 .txt {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 {
    margin-top: 18.66667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 {
    margin-top: 140px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn {
    max-width: 100%;
    padding: 0;
    order: 1;
  }
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie01 {
    position: static;
    margin-left: -20px;
  }
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie01 img {
    width: 100%;
  }
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 {
    width: 38.4vw;
    max-width: 288px;
    margin-top: 2.66667vw;
    margin-left: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .flexColumn .movieColumn .movie02 .txt {
    font-size: 1.2rem;
  }
}

#indexTop .concept .conceptFabric .flexColumn .txtColumn {
  position: relative;
  max-width: 448px;
  width: 100%;
  padding: 0 10px;
  order: 1;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .flexColumn .txtColumn {
    max-width: 100%;
    order: 2;
    padding: 0 0 0 20px;
    margin-top: 4.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .txtColumn {
    margin-top: 32px;
  }
}

#indexTop .concept .conceptFabric .flexColumn .txt {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-bottom: 1.0981vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .txt {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .flexColumn .txt {
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 2.66667vw;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .concept .conceptFabric .flexColumn .txt {
    margin-bottom: 20px;
  }
}

#indexTop .concept .conceptFabric .conceptFabricConLinks a {
  font-size: 1.8rem;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  #indexTop .concept .conceptFabric .conceptFabricConLinks a {
    font-size: 1.2rem;
    line-height: 2;
  }
}

#indexTop .features {
  padding-top: 8.27233vw;
  padding-bottom: 0.73206vw;
  background-color: #f8f8f8;
}

@media screen and (min-width: 1366px) {
  #indexTop .features {
    padding-top: 113px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .features {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features {
    padding-top: 7.06667vw;
    padding-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features {
    padding-top: 53px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features {
    padding-bottom: 10px;
  }
}

#indexTop .features .featuresTit {
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: 1.96px;
  text-align: center;
  color: #736461;
  border-bottom: solid 1px #736461;
  max-width: 720px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  margin-bottom: 5.85652vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    line-height: 1.56;
    letter-spacing: 2.52px;
    width: 89.33333vw;
    max-width: 670px;
    padding-bottom: 1.86667vw;
    margin-bottom: 9.06667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .features .featuresTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresTit {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresTit {
    margin-bottom: 68px;
  }
}

#indexTop .features .featuresCon {
  margin-bottom: 3.95315vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon:not(:last-of-type) {
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon:not(:last-of-type) {
    margin-bottom: 70px;
  }
}

#indexTop .features .featuresCon .featuresConTit {
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0.53px;
  text-align: center;
  color: #736461;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConTit {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .featuresConTit {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    letter-spacing: 0.8px;
    margin-bottom: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .features .featuresCon .featuresConTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConTit {
    margin-bottom: 36px;
  }
}

#indexTop .features .featuresCon .flexColumn {
  display: flex;
  justify-content: center;
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .flexColumn {
    margin-bottom: 10px;
  }
}

#indexTop .features .featuresCon .flexColumn .img {
  max-width: 444px;
  width: 100%;
}

#indexTop .features .featuresCon .flexColumn .img .featuresConTxt {
  padding: 0 10px;
}

#indexTop .features .featuresCon .flexColumn .type {
  max-width: 444px;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .flexColumn {
    flex-wrap: wrap;
  }
  #indexTop .features .featuresCon .flexColumn .img {
    max-width: 100%;
  }
  #indexTop .features .featuresCon .flexColumn .img .featuresConTxt {
    padding: 0;
  }
  #indexTop .features .featuresCon .flexColumn .img img {
    width: 100%;
  }
  #indexTop .features .featuresCon .flexColumn .type {
    max-width: 100%;
  }
  #indexTop .features .featuresCon .flexColumn .type:not(:last-of-type) {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .flexColumn .type:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .flexColumn .type img {
    width: 100%;
  }
}

#indexTop .features .featuresCon .featuresConTxt {
  max-width: 730px;
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin: 0 auto;
}

#indexTop .features .featuresCon .featuresConTxt.center {
  text-align: center;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .featuresConTxt {
    font-size: 1.2rem;
    line-height: 2;
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConTxt {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConTxt {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .featuresConTxt.center {
    text-align: left;
  }
}

#indexTop .features .featuresCon .featuresConSubTit {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  margin-bottom: 1.31772vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConSubTit {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .featuresConSubTit {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    margin-bottom: 7.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConSubTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .features .featuresCon .featuresConSubTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .featuresConSubTit {
    margin-bottom: 57px;
  }
}

#indexTop .features .featuresCon .typeTit {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  margin-bottom: 0.43924vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .typeTit {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .typeTit {
    font-size: 2.6rem;
    font-size: 1.90337vw;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .typeTit {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .features .featuresCon .typeTit {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .typeTit {
    margin-bottom: 10px;
  }
}

#indexTop .features .featuresCon .typeCon .txt {
  font-size: 1.4rem;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  margin-bottom: 1.02489vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .typeCon .txt {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .typeCon .txt {
    font-size: 1.4rem;
    font-size: 1.02489vw;
    margin-bottom: 2.93333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .typeCon .txt {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .features .featuresCon .typeCon .txt {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .typeCon .txt {
    margin-bottom: 22px;
  }
}

#indexTop .features .featuresCon .washingLineup {
  max-width: 730px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 80px;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .washingLineup {
    max-width: 100%;
    margin-bottom: 2.66667vw;
    margin-bottom: 17.73333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup {
    margin-bottom: 133px;
  }
}

#indexTop .features .featuresCon .washingLineup .washingLineupTit {
  max-width: 56px;
  width: 100%;
  height: 56px;
  margin-right: 2.63543vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupTit {
    margin-right: 36px;
  }
}

#indexTop .features .featuresCon .washingLineup .washingLineupTit img {
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupTit {
    width: 14.93333vw;
    max-width: 112px;
    height: auto;
    margin-right: 4.53333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupTit {
    margin-right: 34px;
  }
}

#indexTop .features .featuresCon .washingLineup .washingLineupCon {
  max-width: 625px;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon {
    max-width: 100%;
  }
}

#indexTop .features .featuresCon .washingLineup .washingLineupCon li {
  display: inline-block;
  margin-right: 2.19619vw;
  margin-bottom: 0.36603vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon li {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon li {
    margin-bottom: 5px;
  }
}

#indexTop .features .featuresCon .washingLineup .washingLineupCon li a {
  font-size: 1.8rem;
  font-weight: normal;
  letter-spacing: normal;
  color: #736461;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon li {
    margin-right: 2.66667vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon li {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon li {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .washingLineup .washingLineupCon li a {
    font-size: 1.2rem;
    letter-spacing: -.56px;
  }
}

#indexTop .features .featuresCon .btn01 {
  max-width: 100%;
  margin-top: 3.07467vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .featuresCon .btn01 {
    margin-top: 42px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .features .featuresCon .btn01 {
    max-width: 100%;
    margin-top: 5.6vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .btn01 {
    margin-top: 42px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .featuresCon .btn01 {
    margin-top: 5.6vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .featuresCon .btn01 {
    margin-top: 42px;
  }
}

#indexTop .features .moreContent {
  max-width: 944px;
  margin-top: 20.35139vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent {
    margin-top: 278px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent {
    margin-top: 10.93333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent {
    margin-top: 82px;
  }
}

#indexTop .features .moreContent .toggleCon {
  display: none;
}

#indexTop .features .moreContent .featuresMoreConTit {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.6px;
  text-align: center;
  color: #736461;
  border: solid 1px #707070;
  padding: 11px;
  margin-bottom: 2.2694vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    margin-bottom: 31px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    font-size: 3.2rem;
    font-size: 2.34261vw;
    line-height: 1.88;
    letter-spacing: 0.8px;
    padding-top: 3.2vw;
    padding-bottom: 1.46667vw;
    margin-bottom: 8.53333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    padding-top: 24px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    padding-bottom: 11px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreConTit {
    margin-bottom: 64px;
  }
}

#indexTop .features .moreContent h4 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.45px;
  text-align: left;
  color: #736461;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent h4 {
    font-size: 1.2rem;
    letter-spacing: 0.6px;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent h4 {
    margin-bottom: 35px;
  }
}

#indexTop .features .moreContent p {
  font-size: 1.8rem;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
  color: #736461;
  margin-top: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent p {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent p {
    font-size: 1.2rem;
    line-height: 2;
    margin-top: 4.66667vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent p {
    margin-top: 35px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent p {
    margin-bottom: 60px;
  }
}

#indexTop .features .moreContent .flexColumn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.92826vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .flexColumn {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .flexColumn {
    flex-wrap: wrap;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .flexColumn {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .flexColumn img {
    width: 100%;
  }
}

#indexTop .features .moreContent .flexColumn .flexColumnCon {
  max-width: 49.2%;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .flexColumn .flexColumnCon {
    max-width: 100%;
  }
}

#indexTop .features .moreContent .flexColumn .imgColumn {
  max-width: 49.2%;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .flexColumn .imgColumn {
    max-width: 100%;
  }
}

#indexTop .features .moreContent .flexColumn .txtColumn {
  max-width: 49.2%;
  width: 100%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .flexColumn .txtColumn {
    max-width: 100%;
  }
}

#indexTop .features .moreContent .featuresMoreCon02 p {
  margin-bottom: 2.19619vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 p {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .featuresMoreCon02 p {
    margin-top: 4.66667vw;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 p {
    margin-top: 35px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 p {
    margin-bottom: 70px;
  }
}

#indexTop .features .moreContent .featuresMoreCon02 .flexColumn {
  margin-top: 2.92826vw;
  margin-bottom: 8.05271vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .flexColumn {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .flexColumn {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .featuresMoreCon02 .flexColumn {
    flex-wrap: wrap;
    margin-top: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .flexColumn {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .flexColumn {
    margin-bottom: 0px;
  }
}

#indexTop .features .moreContent .featuresMoreCon02 .txtColumn h4 {
  margin-bottom: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn h4 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn h4 {
    margin-top: 8.53333vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn h4 {
    margin-top: 64px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn h4 {
    margin-bottom: 0px;
  }
}

#indexTop .features .moreContent .featuresMoreCon02 .txtColumn p {
  margin-top: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn p {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn p {
    margin-top: 5.2vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .features .moreContent .featuresMoreCon02 .txtColumn p {
    margin-top: 39px;
  }
}

#indexTop .lineup {
  padding-top: 3.95315vw;
  padding-bottom: 1.61054vw;
  background-color: #fff;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup {
    padding-top: 54px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup {
    padding-bottom: 22px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup {
    padding-top: 10.93333vw;
    padding-bottom: 11.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup {
    padding-top: 82px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup {
    padding-bottom: 85px;
  }
}

#indexTop .lineup .lineupTit {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-bottom: solid 2px #736461;
  padding-bottom: 0.51245vw;
  margin-bottom: 3.51391vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .lineupTit {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .lineupTit {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .lineupTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    line-height: 1.56;
    letter-spacing: 2.52px;
    border-bottom: solid 1px #736461;
    width: 89.33333vw;
    max-width: 670px;
    padding-bottom: 1.86667vw;
    margin-bottom: 12.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .lineup .lineupTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupTit {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupTit {
    margin-bottom: 91px;
  }
}

#indexTop .lineup .lineupCon {
  max-width: 944px;
}

#indexTop .lineup .lineupCon:last-of-type .img {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .lineupCon:last-of-type .img {
    margin-bottom: 0px;
  }
}

#indexTop .lineup .lineupConTit {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  background-color: #f8f8f8;
  border-top: solid 2px #736461;
  border-bottom: solid 2px #736461;
  padding-top: 1.02489vw;
  padding-bottom: 0.73206vw;
  margin-bottom: 1.46413vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    padding-top: 14px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    margin-bottom: 20px;
  }
}

#indexTop .lineup .lineupConTit span {
  display: block;
  font-size: 1.8rem;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .lineupConTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    padding-top: 3.06667vw;
    padding-bottom: 2.53333vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .lineup .lineupConTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    padding-top: 23px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    padding-bottom: 19px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .lineupConTit span {
    font-size: 2.8rem;
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupConTit span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .lineup .lineupConTit span {
    font-size: 3.73333vw;
  }
}

#indexTop .lineup .lineupConTxt {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #343434;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .lineupConTxt {
    font-size: 1.2rem;
    line-height: 2;
    text-align: left;
  }
  #indexTop .lineup .lineupConTxt:not(:last-of-type) {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .lineupConTxt:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

#indexTop .lineup .linupTable {
  table-layout: auto;
  table-layout: fixed;
  margin-top: 4.97804vw;
  margin-bottom: 3.14788vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .linupTable {
    margin-top: 68px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .linupTable {
    margin-bottom: 43px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable {
    margin-top: 7.2vw;
    margin-bottom: 14vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .linupTable {
    margin-top: 54px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .linupTable {
    margin-bottom: 105px;
  }
}

#indexTop .lineup .linupTable thead {
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
}

#indexTop .lineup .linupTable thead th {
  font-weight: normal;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable thead {
    display: none;
  }
}

#indexTop .lineup .linupTable tbody {
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
  border-bottom: solid 2px #736461;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable tbody {
    line-height: 1.56;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable tr {
    border-top: solid 1px #736461;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .linupTable tr {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .linupTable tr {
    padding-bottom: 10px;
  }
}

#indexTop .lineup .linupTable td {
  border-top: solid 2px #736461;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable td {
    border-top: none;
    display: block;
  }
}

#indexTop .lineup .linupTable .productImg {
  max-width: 23%;
  width: 100%;
  padding: 5px 4%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable .productImg {
    grid-area: 2 / 1 / 4 / 2;
    max-width: 100%;
    width: 100%;
    padding: 0;
    width: 36vw;
    max-width: 270px;
  }
}

#indexTop .lineup .linupTable .productName {
  max-width: 27%;
  width: 100%;
  padding: 5px 2%;
}

#indexTop .lineup .linupTable .productName .flexColumn {
  display: flex;
  align-items: center;
}

#indexTop .lineup .linupTable .productName .img {
  max-width: 31px;
  width: 100%;
  margin: 0;
  margin-right: 0.73206vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .linupTable .productName .img {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable .productName {
    grid-area: 1 / 1 / 2 / 2;
    max-width: 100%;
    width: 100%;
    align-self: center;
    padding: 0;
    padding-right: 2.66667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .linupTable .productName {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable .productName .img {
    display: none;
  }
}

#indexTop .lineup .linupTable .productHard {
  max-width: 20%;
  width: 100%;
  padding: 5px 2%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable .productHard {
    grid-area: 1 / 2 / 3 / 3;
    max-width: 100%;
    width: 100%;
    align-self: center;
    padding: 0;
  }
  #indexTop .lineup .linupTable .productHard::before {
    content: "";
    display: block;
    width: 6.8vw;
    max-width: 51px;
    height: 6.8vw;
    max-height: 51px;
    background-image: url(/images/common/icon_washing.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .linupTable .productHard::before {
    margin-bottom: 40px;
  }
}

#indexTop .lineup .linupTable .productNum {
  max-width: 20%;
  width: 100%;
  padding: 5px 2%;
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .linupTable .productNum {
    grid-area: 3 / 2 / 4 / 3;
    max-width: 100%;
    width: 100%;
    align-self: start;
    padding: 0;
  }
}

#indexTop .lineup .img {
  max-width: 721px;
  margin: 0 auto;
  margin-bottom: 6.95461vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .lineup .img {
    margin-bottom: 95px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .lineup .img {
    max-width: 100%;
    margin-bottom: 24.26667vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .lineup .img {
    margin-bottom: 182px;
  }
}

#indexTop .shop {
  background-image: url(/images/shop/purchase_back.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 4.90483vw;
  padding-bottom: 5.19766vw;
  position: relative;
}

@media screen and (min-width: 1366px) {
  #indexTop .shop {
    padding-top: 67px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .shop {
    padding-bottom: 71px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop {
    padding-top: 10.93333vw;
    padding-bottom: 16.53333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop {
    padding-top: 82px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop {
    padding-bottom: 124px;
  }
}

#indexTop .shop .shopTit {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  font-family: "YuMincho", "游明朝", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-bottom: solid 2px #736461;
  padding-bottom: 0.51245vw;
  margin-bottom: 6.36896vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .shop .shopTit {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .shop .shopTit {
    margin-bottom: 87px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop .shopTit {
    font-size: 3.6rem;
    font-size: 2.63543vw;
    line-height: 1.56;
    letter-spacing: 2.52px;
    border-bottom: solid 1px #736461;
    width: 89.33333vw;
    max-width: 670px;
    padding-bottom: 1.86667vw;
    margin-bottom: 8.13333vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .shopTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767.9px) and (max-width: 767.9px) {
  #indexTop .shop .shopTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .shopTit {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .shopTit {
    margin-bottom: 61px;
  }
}

#indexTop .shop .shopLinks {
  max-width: 720px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

#indexTop .shop .shopLinks li {
  padding-left: 3.66032vw;
  padding-right: 3.66032vw;
}

@media screen and (min-width: 1366px) {
  #indexTop .shop .shopLinks li {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .shop .shopLinks li {
    padding-right: 50px;
  }
}

#indexTop .shop .shopLinks a {
  font-size: 2.1rem;
  line-height: 1.76;
  letter-spacing: normal;
  text-align: left;
  color: #58595b;
  text-decoration: underline;
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop .shopLinks {
    max-width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
  #indexTop .shop .shopLinks li {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .shopLinks li {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .shopLinks li {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop .shopLinks li:not(:last-of-type) {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .shopLinks li:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop .shopLinks a {
    font-size: 1.6rem;
  }
}

#indexTop .shop .pageTopFixed {
  position: absolute;
  width: 7.32064vw;
  max-width: 100px;
  right: 9.51684vw;
  top: -1.46413vw;
  cursor: pointer;
  z-index: 10000;
}

@media screen and (min-width: 1366px) {
  #indexTop .shop .pageTopFixed {
    right: 130px;
  }
}

@media screen and (min-width: 1366px) {
  #indexTop .shop .pageTopFixed {
    top: -20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .pageTopFixed {
    right: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1366px) {
  #indexTop .shop .pageTopFixed {
    right: 60px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop .pageTopFixed {
    position: fixed;
    width: 13.33333vw;
    max-width: 100px;
    height: 13.33333vw;
    max-height: 100px;
    right: 7.33333vw;
    top: auto;
    bottom: 20px;
    transition: opacity .8s .8s;
  }
}

@media screen and (max-width: 767.9px) and (min-width: 1366px) {
  #indexTop .shop .pageTopFixed {
    right: 55px;
  }
}

@media screen and (max-width: 767.9px) {
  #indexTop .shop .pageTopFixed.hidden {
    opacity: 0;
    visibility: hidden;
  }
}

@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;

  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;

  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

button{
  background-color: transparent;
}

main {
  position: relative;
  display: block;
  width: 100%;

  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.container {
  max-width: 1128px;
  padding: 0 20px;
  margin: auto;
}

.max-container {
  max-width: 1326px;
  padding: 0 20px;
  margin: auto;
}

a {
  color: #555;
}

.btn01 {
  max-width: 500px;
  display: block;
  width: 100%;
  font-family: $base-font;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-radius: 20px;
  background-color: #ffffff;
  @include props(pt, 30);
  @include props(pb, 30);
  @include props(pl, 15);
  @include props(pr, 15);
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    background-image: url(/images/common/icon_win.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 28px;
    height: 26px;
    @include props(ml, 20);
    vertical-align: sub;
  }

  &:hover {
    opacity: 0.7;
    transition: opacity .3s;
  }

  @include mq(tab) {
    max-width: 49%;
    font-size: 1.6rem;

    &::after {
      width: 24px;
      height: 24px;
    }
  }

  @include mq(md) {
    max-width: 100%;
    @include fz_vw(24);
    border-radius: 15px;
    @include props(pt, 35, 750);
    @include props(pb, 35, 750);

    &::after {
      @include props(w, 30, 750);
      @include props(h, 30, 750);
      @include props(ml, 35, 750);
    }
  }
}

.btn02 {
  max-width: 498px;
  display: block;
  width: 100%;
  font-family: $base-font;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  border-radius: 96px;
  background-color: #f5f5f5;
  @include props(pt, 30);
  @include props(pb, 30);
  @include props(pl, 15);
  @include props(pr, 15);
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    background-image: url(/images/common/icon_win.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 28px;
    height: 26px;
    @include props(ml, 20);
    vertical-align: sub;
  }

  &:hover {
    opacity: 0.7;
    transition: opacity .3s;
  }

  @include mq(md) {
    max-width: 100%;
    @include fz_vw(24);
    border-radius: 15px;
    @include props(pt, 35, 750);
    @include props(pb, 35, 750);

    &::after {
      @include props(w, 30, 750);
      @include props(h, 30, 750);
      @include props(ml, 35, 750);
    }
  }
}

.btn03 {
  a{
    max-width: 500px;
    display: block;
    width: 100%;
    font-family: $base-font;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #736461;
    border-radius: 20px;
    background-color: #ffffff;
    @include props(pt, 30);
    @include props(pb, 30);
    @include props(pl, 15);
    @include props(pr, 15);
    cursor: pointer;

    &::after {
      content: "";
      display: inline-block;
      background-image: url(/images/common/icon_win.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 28px;
      height: 26px;
      @include props(ml, 20);
      vertical-align: sub;
    }

    &:hover {
      opacity: 0.7;
      transition: opacity .3s;
    }

    @include mq(tab) {
      max-width: 49%;
      font-size: 1.6rem;

      &::after {
        width: 24px;
        height: 24px;
      }
    }

    @include mq(md) {
      max-width: 100%;
      @include fz_vw(24);
      border-radius: 15px;
      @include props(pt, 35, 750);
      @include props(pb, 35, 750);

      &::after {
        @include props(w, 30, 750);
        @include props(h, 30, 750);
        @include props(ml, 35, 750);
      }
    }
  }

  @include mq(md) {
    max-width: 100%;
    width: 100%;
  }
}

.toggleBtn {
  max-width: 498px;
  width: 100%;
  @include props(pt, 30);
  @include props(pb, 30);
  @include props(pl, 15);
  @include props(pr, 15);
  margin: 0 auto;
  @include props(mb, 35);
  border-radius: 96px;
  background-color: #ffffff;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #736461;
  cursor: pointer;

  &::after {
    content: "";
    display: inline-block;
    vertical-align: baseline;
    width: 15px;
    height: 15px;
    border-right: solid 3px #736461;
    border-bottom: solid 3px #736461;
    transform: rotate(45deg);
    @include props(ml, 18);
    transition: transform .6s;
  }

  &.open{
    &::after{
      transform: rotate(225deg);
      transform-origin: 50% 60%;
    }
  }

  @include mq(md) {
    @include fz_vw(24);
    border-radius: 15px;
    @include props(pt, 38, 750);
    @include props(pb, 38, 750);
    @include props(mb, 108, 750);

    &::after {
      vertical-align: top;
      @include props(w, 14, 750);
      @include props(h, 14, 750);
      @include props(ml, 30, 750);
    }
  }
}

/* IE11とEdge表示用のCSS　*/
@media all and (-ms-high-contrast: none) {
  #indexTop .features .moreContent .featuresMoreConTit {
    padding: 15px 11px 5px;
  }
  // #indexTop .message{
  //   position: relative;
  //   top: 0;
  //   padding: 0 0 0 !important;
  // }
  // #indexTop .message .container{
  //   position: static;
  //   transform: translate(0) !important;
  // }
}

.modalChart {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 901px;
  width: 100%;
  z-index: 99999;
  transition: opacity .4s ease-in-out;
  opacity: 0;
  visibility: hidden;

  @include mq(tab) {
    max-width: 95%;
  }

  @include mq(md) {
    max-width: 100%;
  }

  &.isShow {
    opacity: 1;
    visibility: visible;
  }

  .modalChartCon {
    position: relative;
    background-color: #fff;
    border: solid 1px #707070;
    @include props(pt, 100);
    @include props(pr, 40);
    @include props(pl, 40);
    @include props(pb, 84);
    max-height: 95vh;
    overflow-y: scroll;

    @include mq(tab) {
      @include props(pt, 100, 750);
      @include props(pb, 84, 750);
    }

    @include mq(md) {
      max-height: 100vh;
      @include props(pt, 100, 750);
      @include props(pr, 10, 750);
      @include props(pl, 10, 750);
      @include props(pb, 71, 750);
    }
  }

  button {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      transform: rotate(180deg);
      transform-origin: center;
    }

    span {
      display: block;
      width: 50px;
      height: 3px;
      background-color: #434343;
      position: absolute;
      top: 45%;
      left: -20%;

      &:nth-of-type(1) {
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }

    @include mq(md) {
      @include props(t, 25, 750);
      @include props(r, 24, 750);
      @include props(w, 30, 750);
      @include props(h, 30, 750);

      span {
        @include props(w, 43, 750);
        height: 1px;
      }
    }
  }

  .modalChartStart {
    border-radius: 15px;
    background-color: #fffef2;
    @include props(pt, 30);
    @include props(pb, 10);
    box-shadow: 3px 2px 17px -3px #888;

    .txt {
      max-width: 32%;
      width: 100%;
    }

    @include mq(md) {
      border-radius: 7px;
      @include props(pt, 30, 750);
      @include props(pb, 14, 750);
    }
  }

  .modalChartStartTit {
    font-family: $Sawarabi;
    font-size: 3.9rem;
    font-weight: 500;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #c7c8ca;
    margin: 0 0 15px;

    @include mq(md) {
      @include fz_vw(32);
      @include props(mb, 15, 750);
    }
  }

  .flexColumn {
    display: flex;
    justify-content: space-between;
  }

  .modalChartStartLead {
    font-family: $Kiwi;
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 0.93;
    letter-spacing: -1.12px;
    text-align: center;
    color: #4d4d4f;
    margin: 0 0 15px;

    @include mq(md) {
      @include fz_vw(25);
      @include props(mb, 15, 750);
    }
  }

  .txt {
    p {
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 1.72;
      letter-spacing: -1.44px;
      text-align: center;
      color: #4d4d4f;

      @include mq(md) {
        @include fz_vw(16);
        letter-spacing: -1.12px;
      }
    }
  }

  .softAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #fbe5d6;
    margin: 5px auto 8px;

    a {
      display: block;
      font-size: 1.6rem;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: -1.28px;
      text-align: center;
      color: #000000;
      text-decoration: underline;
    }

    @include mq(md) {
      @include props(w, 105, 750);
      @include props(h, 105, 750);

      a{
        @include fz_vw(14);
        font-feature-settings: "palt" 1;
        letter-spacing: -1.56px;
      }
    }
  }

  .moistAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff2cc;
    margin: 5px auto 8px;

    a {
      display: block;
      font-size: 1.6rem;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: -1.28px;
      text-align: center;
      color: #000000;
      text-decoration: underline;
    }

    @include mq(md) {
      @include props(w, 105, 750);
      @include props(h, 105, 750);

      a{
        @include fz_vw(14);
        font-feature-settings: "palt" 1;
        letter-spacing: -1.56px;
      }
    }
  }

  .solidAnswer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #d6dce5;
    margin: 5px auto 8px;

    a {
      display: block;
      font-size: 1.5rem;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: -1.28px;
      text-align: center;
      color: #000000;
      text-decoration: underline;
    }

    @include mq(md) {
      @include props(w, 105, 750);
      @include props(h, 105, 750);

      a{
        @include fz_vw(14);
        font-feature-settings: "palt" 1;
        letter-spacing: -1.56px;
      }
    }
  }

  .typeTxt {
    max-width: 100%;
    width: 60%;
    margin: 20px auto 0;

    img {
      margin: 0 auto;
    }
  }

  .arrow {
    max-width: 32%;
    width: 100%;
    margin: 5px 0 2px;

    img {
      max-width: 27px;
      width: 100%;
      margin: 0 auto;

      @include mq(md) {
        @include props(w, 37, 750);
      }
    }
  }

  .chart01,
  .chart02,
  .chart03 {
    max-width: 32%;
    width: 100%;

    .txt {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 106px;
      border-radius: 10px;
      background-color: #fffef2;
      box-shadow: 3px 2px 17px -3px #888;

      @include mq(md) {
        @include props(h, 120, 750);
        border-radius: 5px;
      }
    }
  }

  .yes {
    max-width: 50%;
    width: 100%;
    margin: 10px 0 2px;

    img {
      max-width: 61px;
      width: 100%;
      margin: 0 45px;

      @include mq(md) {
        @include props(w, 71, 750);
        @include props(mr, 30, 750);
        @include props(ml, 30, 750);
      }
    }
  }

  .no {
    max-width: 50%;
    width: 100%;
    margin: 10px 0 2px;

    img {
      max-width: 51px;
      width: 100%;
      margin: 0 45px;

      @include mq(md) {
        @include props(w, 61, 750);
        @include props(mr, 30, 750);
        @include props(ml, 30, 750);
      }
    }
  }
}

.modalQr {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 901px;
  width: 100%;
  z-index: 99999;
  transition: opacity .4s ease-in-out;
  opacity: 0;
  visibility: hidden;

  @include mq(tab) {
    max-width: 95%;
  }

  &.isShow {
    opacity: 1;
    visibility: visible;
  }

  .modalQrCon {
    position: relative;
    background-color: #fff;
    border: solid 1px #707070;
    @include props(pt, 100);
    @include props(pr, 40);
    @include props(pl, 40);
    @include props(pb, 95);
    max-height: 95vh;

    @include mq(tab) {
      @include props(pt, 100, 750);
      @include props(pb, 95, 750);
    }

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pr, 10, 750);
      @include props(pl, 10, 750);
      @include props(pb, 71, 750);
    }
  }

  button {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      transform: rotate(180deg);
      transform-origin: center;
    }

    span {
      display: block;
      width: 50px;
      height: 3px;
      background-color: #434343;
      position: absolute;
      top: 45%;
      left: -20%;

      &:nth-of-type(1) {
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }

    @include mq(md) {
      @include props(t, 25, 750);
      @include props(r, 24, 750);
      @include props(w, 30, 750);
      @include props(h, 30, 750);

      span {
        @include props(w, 43, 750);
        height: 1px;
      }
    }
  }

  p {
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: center;
    color: #736461;

    @include mq(md) {
      font-size: 1.4rem;
      line-height: 2;
    }
  }

  .img {
    max-width: 155px;
    width: 100%;
    margin: 0 auto;
    @include props(mt, 45);
  }
}

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  display: none;

  .modalBack{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
    cursor: pointer;
  }

  .modalMovie{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 1064px;
    width: 100%;
    padding: 0 20px;
    cursor: pointer;
    z-index: 2;

    video{
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    @include mq(md) {
      top: 20vh;
      transform: translateX(-50%);
    }
  }

  .jsMovie{
    position: relative;

    &.js-movie_active{
      .videoIconPlay{
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  
  .jsMovieButton{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height: 85%;
    cursor:pointer;
    transition:opacity 200ms ease-out;

    @include mq(tab) {
      top:10%;
      height: 70%;
    }

    @include mq(md) {
      top:10%;
      height: 70%;
    }
  }

  .videoIcon{
    position: absolute;
    font-size: 20px;
    z-index: 2;
    top: 58%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 60px;
    height: 60px;
    pointer-events:none;

    @include mq(tab) {
      top: 56%;
      @include props(w, 60, 750);
      @include props(h, 60, 750);
    }

    @include mq(md) {
      @include props(w, 120, 750);
      @include props(h, 120, 750);
    }
  }

  button {
    position: relative;
    display: block;
    margin: 0 0 0 auto;
    @include props(mb, 10);
    width: 37px;
    height: 37px;
    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover {
      transform: rotate(180deg);
      transform-origin: center;
    }

    span {
      display: block;
      width: 50px;
      height: 3px;
      background-color: #333;
      position: absolute;
      top: 45%;
      left: -20%;

      &:nth-of-type(1) {
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }

    @include mq(md) {
      margin: 0 0 0 auto;
      @include props(mb, 20, 750);
      @include props(w, 30, 750);
      @include props(h, 30, 750);

      span {
        @include props(w, 43, 750);
        height: 1px;
      }
    }
  }
}

.fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s .6s, transform .5s .5s;
  &.active {
    opacity: 1;
    transform: none;
  }

  @include mq(md) {
    transform: translateY(10px);
  }
}

.fadeInClickToggleCon{
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s .6s, transform .5s .5s;
  &.active {
    opacity: 1;
    transform: none;
  }
}

.fadeInToggleCon{
  opacity: 0;
  transform: translateY(20px);
  transition: opacity .6s .6s, transform .5s .5s;
  &.active {
    opacity: 1;
    transform: none;
  }
}

/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
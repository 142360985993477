@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;

  @include mq(md) {
    position: fixed;
  }

  .headerContents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include props(h, 110);

    @include mq(tab) {
      @include props(h, 140);
    }

    @include mq(md) {
      display: block;
      padding: 0;
      @include props(h, 120, 750);
    }
  }

  .headerLogo {
    display: flex;
    align-items: center;
    height: 100%;
    @include props(mr, 49);

    @include mq(md) {
      width: 100%;
      justify-content: center;
      position: relative;
      z-index: 2;
      background-color: #fff;
    }

    a {
      @include props(w, 265);

      @include mq(md) {
        @include props(w, 257, 750);
      }

      transition: .5s;
      display: block;
      position: relative;
    }

    img {
      transition: opacity .5s;

      @include mq(md) {
        width: 100%;
      }
    }
  }

  .navBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    @include props(p, 40, 750);
    @include props(w, 130, 750);
    @include props(h, 120, 750);
    cursor: pointer;
    z-index: 3;

    span {
      display: inline-block;
      position: absolute;
      @include props(l, 40, 750);
      @include props(w, 50, 750);
      height: 1px;
      background-color: #000;
      transition: .5s ease-in-out;
      &:nth-of-type(1) {
        @include props(t, 40, 750);
      }
      &:nth-of-type(2) {
        top: 49%;
      }
      &:nth-of-type(3) {
        @include props(b, 40, 750);
      }
    }
    &.open {
      span {
        &:nth-of-type(1),
        &:nth-of-type(3) {
          top: 50%;
          background-color: #222;
        }
        &:nth-of-type(1) {
          transform: rotate(-135deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(135deg);
        }
      }
    }
  }

  .headerNav{
    max-width: 100%;
    width: 100%;

    @include mq(md) {
      position: absolute;
      left: 0;
      max-height: 100vh;
      transform: translateY(-100%);
      z-index: 1;
    }
    
    .otherLinks{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include props(mb, 16);

      @include mq(tab) {
        @include props(mb, 26);
      }

      ul{
        display: flex;
        align-items: center;
      }

      .lang{
        position: relative;

        a{
          display: flex;
          align-items: center;
          font-size: 1.1rem;
          font-weight: normal;
          letter-spacing: normal;
          text-align: left;
          color: #231815;
          @include props(pr, 11);
          @include props(pl, 11);

          &::after{
            display: block;
            content: "";
            background-image: url(/images/common/lang_triangle.svg);
            background-size: contain;
            background-repeat: no-repeat;
            @include props(w, 7);
            @include props(h, 6);
            @include props(ml, 11);
            transition: transform .6s;
          }
        }

        .langChange{
          &.open{
            a{
              &::after{
                transform: rotate(180deg);
                transform-origin: center;
              }
            }
          }
        }

        img{
          @include props(w, 27);
          @include props(mr, 4);
        }

        .langChangeCon{
          display: none;
          flex-wrap: wrap;
          position: absolute;
          left: 0;
          background-color: #fff;
          width: 100%;

          li{
            width: 100%;
          }

          a{
            display: block;
            text-align: center;
            @include props(pt, 5);
            @include props(pb, 5);

            &::after{
              display: none;
            }
          }
        }
      }

      .otherLinksCon{
        border-right: solid 1px #707070;
        line-height: 1.57;

        &:nth-of-type(2){
          border-left: solid 1px #707070;
        }

        a{
          display: block;
          font-size: 1.1rem;
          font-weight: normal;
          letter-spacing: normal;
          text-align: left;
          color: #231815;
          @include props(pr, 11);
          @include props(pl, 11);
        }

        &.red{
          border-right: none;

          a{
            color: #d7053c;
            display: flex;
            align-items: center;

            &::before{
              content: "";
              display: block;
              @include props(w, 27);
              @include props(h, 27);
              background-image: url(/images/common/icon_store_red.svg);
              background-repeat: no-repeat;
              background-size: contain;
              @include props(mr, 8);
            }
          }
        }
      }

      .sns{
        @include props(ml, 10);

        .snsCon{
          @include props(w, 27);
          @include props(h, 27);
          @include props(ml, 9);
        }
      }
    }

    .globalNav{
      &>ul{
        display: flex;
        align-items: center;
      }
      .globalNavCon{
        &:not(:last-of-type){
          @include props(mr, 50);
        }

        a{
          font-size: 1.4rem;
          font-weight: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: left;
          color: #736461;
        }
      }

      @include mq(md) {
        max-width: 100%;
        width: 100%;

        &>ul{
          max-width: 100%;
          width: 100%;
          height: 100vh;
          display: block;
          @include props(pt, 32, 750);
          @include props(pb, 182, 750);
          background-color: #fff;
          overflow-y: scroll;
        }
        .globalNavCon{
          opacity: 0;

          &:not(:last-of-type){
            @include props(mr, 0);

            a{
              @include props(mb, 74, 750);
            }
          }
  
          a{
            display: block;
            font-size: 1.8rem;
            line-height: 1;
            text-align: center;
          }

          .langChangeCon{
            display: none;
          }

          &.globalNavConSmall{
            border-top: solid 1px #ccc;

            a{
              font-size: 1.5rem;
              margin-bottom: 0;
              @include props(pt, 30, 750);
              @include props(pb, 30, 750);
            }
          }

          &.red{
            background-color: #d7053c;

            a{
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              @include props(pt, 19, 750);
              @include props(pb, 19, 750);

              &::before{
                content: "";
                display: block;
                @include props(w, 50, 750);
                @include props(h, 50, 750);
                background-image: url(/images/common/icon_store_white.svg);
                background-repeat: no-repeat;
                background-size: contain;
                @include props(mr, 20, 750);
              }
            }
          }

          .sns{
            display: flex;
            align-items: center;
            justify-content: center;
            @include props(mt, 48, 750);
    
            .snsCon{
              @include props(w, 64, 750);
              @include props(h, 64, 750);
              @include props(ml, 30, 750);
              @include props(mr, 30, 750);
            }
          }

        }
        .lang{
          position: relative;
          @include props(mt, 16, 750);
          @include props(mb, 0, 750);
  
          .langChange{
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            line-height: 1;
            text-align: center;
            justify-content: center;
            color: #736461;
            padding: 0;
            @include props(pt, 30, 750);
            @include props(pb, 21, 750);
            @include props(mb, 0, 750);

            &.open{
              &::after{
                transform: rotate(180deg);
                transform-origin: center;
              }
            }

            img{
              @include props(w, 45, 750);
              @include props(mr, 15, 750);
            }
  
            &::after{
              display: block;
              content: "";
              background-image: url(/images/common/lang_triangle.svg);
              background-size: contain;
              background-repeat: no-repeat;
              @include props(w, 17, 750);
              @include props(h, 16, 750);
              @include props(ml, 15, 750);
              transition: transform .6s;
            }
          }

          .langChangeCon{
            a{
              border-top: solid 2px #fff;
              background-color: #f7f7f7;
              @include props(pt, 28, 750);
              @include props(pb, 28, 750);
            }
          }
        }
      }
    }
  }
}